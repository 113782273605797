import request from 'shared/request';
import { variantRequested, variantSuccess, variantFailed } from 'data/Variant/actions/actions';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';
import { saveBarcode } from 'data/Barcode/actions/saveBarcode';

//https://docs.readystock.app/#create-variations
export function saveVariant(parameters = {}) {
    return dispatch => {
        dispatch(variantRequested());
        request.post('variations', parameters.variant)
        .then(data => {
            dispatch(variantSuccess(data));
            dispatch(appMessageSuccess([{ message: 'Variant added.', level: 'success' }]));
            //will need to check if barcode stuff exists
            if (data.data[0].variationId && parameters.barcode[0].barcode !== '') {
                const values = parameters.barcode[0];

                const barcodeData = new Array({
                    variationId: data.data[0].variationId,
                    productId: values.productId,
                    barcodeTypeId: values.barcodeTypeId,
                    barcode: values.barcode,
                });

                dispatch(saveBarcode(barcodeData));
            }
            return data;
        })
        .catch(error => {
            dispatch(variantFailed(error));

            // dispatch(appMessageSuccess([{ message: error, level: 'error' }]));
            return error;
        });
    }
}

export function updateVariant(parameters = {}) {
    return dispatch => {
        dispatch(variantRequested());
        request.put('variations', parameters.variant)
        .then(data => {
            dispatch(variantSuccess(data));
            dispatch(appMessageSuccess([{ message: 'Variant added.', level: 'success' }]));
            // dispatch(saveBarcode(barcodeData));

            return data;
        })
        .catch(error => {
            dispatch(variantFailed(error));

            // dispatch(appMessageSuccess([{ message: error, level: 'error' }]));
            return error;
        });
    }

}
import React, { ReactElement } from 'react';
import { Paper, TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { ExtendedProp } from 'Utils/CommonJS';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    image: {
        maxHeight: '7em'
    }
});

export enum RSTableInfoCellType {
    Text,
    Image,
    Element
}

export class RSTableInfo {
    Headers: string[]
    Rows: RSTableInfoRow[]
    OpenUrl!: string
    TopCheckBoxIsChecked: boolean

    public constructor(headers: string[], rows: RSTableInfoRow[], TopCheckBoxIsChecked: boolean) {
        this.Headers = headers;
        this.Rows = rows;
        this.TopCheckBoxIsChecked = TopCheckBoxIsChecked;
    }

    public static BuildRSTableInfo(list: object[], keyColumnName: string, columns: RSTableInfoColumn[], details: { [id: string]: TableRowState }, TopCheckBoxIsChecked: boolean): RSTableInfo {
        let columnLabels: string[] = [];

        for (var colIndex in columns) {
            let column = columns[colIndex];
            columnLabels[columnLabels.length] = column.Label;
        }

        let rows: RSTableInfoRow[] = [];
        for (var index in list) {
            let item = list[index];

            let row: RSTableInfoRow = new RSTableInfoRow(String(ExtendedProp(item, keyColumnName)), []);
            row.Record = item;

            if (details !== null) {
                let detail = details[row.Key];
                if (detail)
                    row.IsChecked = detail.IsChecked;
            }

            for (var cIndex in columns) {
                var column = columns[cIndex];
                if (column.Name !== keyColumnName) {
                    let cell: RSTableInfoCell;
                    if (column.ElementFunction !== null && column.ElementFunction !== undefined) {
                        cell = new RSTableInfoCell(null, RSTableInfoCellType.Element, row, column);
                    } else {
                        let val = ExtendedProp(item, column.Name);
                        let valResult: string;
                        if (val instanceof Date) {
                            valResult = (val as Date).toDateString();
                        } else {
                            valResult = String(val);
                        }
                        cell = new RSTableInfoCell(valResult, RSTableInfoCellType.Text, row, column);
                    }
                    row.Cells[row.Cells.length] = cell;
                }
            }

            rows[rows.length] = row;
        }

        return new RSTableInfo(columnLabels, rows, TopCheckBoxIsChecked);
    }
}

export class TableRowState {
    public IsChecked!: boolean

    public static Default(): TableRowState {
        return {
            IsChecked: false
        };
    }

    public static DefaultFromList<T>(l: T[], key: string): { [id: string]: TableRowState } {
        let result: { [id: string]: TableRowState } = {};
        [...l].forEach(x =>
            result[ExtendedProp(x, key)] = TableRowState.Default()
        );
        return result;
    }
}

export class RSTableInfoRow {
    Key: string
    Cells: RSTableInfoCell[]
    Record!: object
    IsChecked!: boolean

    public constructor(key: string, cells: RSTableInfoCell[]) {
        this.Key = key;
        this.Cells = cells;
    }
}

export class RSTableInfoCell {
    Value: string | null;
    Type: RSTableInfoCellType;

    Row: RSTableInfoRow;
    Column: RSTableInfoColumn;

    public constructor(Value: string | null, Type: RSTableInfoCellType, Row: RSTableInfoRow, Column: RSTableInfoColumn) {
        this.Value = Value;
        this.Type = Type;
        this.Row = Row;
        this.Column = Column;
    }
}

export class RSTableInfoColumn {
    Name!: string
    Label!: string
    ElementFunction!: ((props: { record: object, index: number }) => JSX.Element) | null;

    public constructor(init?: Partial<RSTableInfoColumn>) {
        Object.assign(this, init);
    }
}

export interface RSTableProps {
    info: RSTableInfo
    checkRowFunction: (RowKey: string) => void,
    checkAllRowsFunction: () => void
}

export default function RSTable(props: RSTableProps) {

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell component="th">
                            <Checkbox className="RSTable_CheckAll" checked={props.info.TopCheckBoxIsChecked} onClick={() => props.checkAllRowsFunction()} style={{ padding: 0 }}></Checkbox>
                        </TableCell>
                        {props.info.Headers.map((header, index) => (
                            <TableCell align={index === 0 ? "left" : "right"} key={"RSTableHeaderCell" + index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.info.Rows.map((row, rowIndex) => (
                        <TableRow key={"RSTableRow" + row.Key}>
                            <TableCell key={"RSTableCheckBoxCell" + rowIndex}>
                                <Checkbox key={"RSTableCheckBox" + rowIndex} className="RSTableCheckBox" checked={row.IsChecked} onClick={() => props.checkRowFunction(row.Key)} style={{ padding: 0 }}></Checkbox>
                            </TableCell>
                            <TableCell component="th" scope="row" key={"RSTableNavigationCell" + rowIndex}>
                                {(props.info.OpenUrl === null || props.info.OpenUrl === undefined) && row.Key}
                                {(props.info.OpenUrl !== null && props.info.OpenUrl !== undefined) &&
                                    <RouterLink to={props.info.OpenUrl + row.Key}>Open</RouterLink>
                                }
                            </TableCell>
                            {row.Cells.map((cell: RSTableInfoCell, cellIndex: number) => {
                                let CellElementFunction = function (props: { record: any }) {
                                    if (cell.Column.ElementFunction !== null && cell.Column.ElementFunction !== undefined)
                                        return cell.Column.ElementFunction({ record: cell.Row.Record, index: cellIndex });
                                    else
                                        return (
                                            <div></div>
                                        );
                                };

                                switch (cell.Type) {
                                    case RSTableInfoCellType.Image:
                                        return (
                                            < TableCell align="right" key={"RSTableCell" + rowIndex + "-" + cellIndex}>
                                                <img src={cell.Value ?? ""} className={classes.image} alt="" />
                                            </TableCell>
                                        )
                                    case RSTableInfoCellType.Element:
                                        return (
                                            <TableCell align="right" key={"RSTableCell" + rowIndex + "-" + cellIndex}>
                                                <CellElementFunction record={cell.Row.Record}></CellElementFunction>
                                            </TableCell>
                                        )
                                    default:
                                        return <TableCell align="right" key={"RSTableCell" + rowIndex + "-" + cellIndex}>{cell.Value}</TableCell>
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer>
    );
}
import React from 'react';
import RSTable, { RSTableInfo } from 'Components/RSTable';
import { useDispatch } from 'react-redux';
import {
    TextField,
    Grid,
    Button,
    Menu,
    MenuItem,
    Box,
    makeStyles,
    Theme,
    createStyles
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { SearchState, SearchActions, SearchStatus } from 'Actions/SearchActions';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkButton from 'Components/LinkButton';

interface Props {
    RSTableInfo: RSTableInfo
    SearchActions: SearchActions
    SearchState: SearchState
    NewRecordUrl?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export default function Search(props: Props) {

    let dispatch = useDispatch();
    let status = props.SearchState.Status;
    let showAdvancedFilters: boolean = props.SearchState.ShowAdvancedFilters;
    let menuElement: Element | null = props.SearchState.MenuAnchorElement;

    let toggleAdvancedFiltersFun = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        dispatch(props.SearchActions.ToggleAdvancedFilters());
        e.preventDefault();
    }

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        dispatch(props.SearchActions.SetMenuAnchorElement(e.currentTarget));
    };

    const handleClose = () => {
        dispatch(props.SearchActions.SetMenuAnchorElement(null));
    };

    let menuItemStyleRight = {
        textAlign: "right" as "right"
    };

    let menuItemStyleCenter = {
        textAlign: "center" as "center"
    };

    let giveItSpace = {
        marginBottom: "1em"
    };

    let refreshGrid = () => {
        dispatch(props.SearchActions.RefreshGrid());
    };

    let checkRow = (RowKey: string) => {
        dispatch(props.SearchActions.CheckRow(RowKey));
    }

    let checkAllRows = () => {
        dispatch(props.SearchActions.CheckAllRows());
    }

    const classes = useStyles();

    return (
        <div>
            <Grid container spacing={3} style={giveItSpace}>
                <Grid item xs={12}>
                    <TextField id="" label="Search" fullWidth></TextField>
                </Grid>
            </Grid>
            {showAdvancedFilters &&
                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                    <Grid item md={3}>
                        <TextField label="Filter 1" fullWidth />
                    </Grid>
                </Grid>
            }
            <Grid container spacing={3} style={giveItSpace}>
                <Grid item xs={6} lg={6} style={menuItemStyleCenter}>
                    <Pagination count={10} />
                </Grid>
                <Grid item xs={6} lg={6} style={menuItemStyleRight}>
                    <IconButton className="SearchRefresh" onClick={refreshGrid}>
                        <Refresh />
                    </IconButton>
                    <Button onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => toggleAdvancedFiltersFun(e)}>Advanced Filters</Button>
                    <Box display={{ xs: 'none', lg: 'inline' }}>
                        <LinkButton to={props.NewRecordUrl ?? ""}>
                            New
                        </LinkButton>
                        <Button>Edit</Button>
                        <Button>Delete</Button>
                    </Box>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuElement}
                        keepMounted
                        open={Boolean(menuElement)}
                        onClose={handleClose}
                    >
                        <Box display={{ xs: 'inline', lg: 'none' }}>
                            <MenuItem onClick={handleClose}>Delete</MenuItem>
                        </Box>
                        <Box display={{ xs: 'inline', lg: 'none' }}>
                            <MenuItem onClick={handleClose}>Edit</MenuItem>
                        </Box>
                        <MenuItem onClick={handleClose}>Export</MenuItem>
                        <MenuItem onClick={handleClose}><CloudDownloadIcon />&nbsp;Download Import Template</MenuItem>
                        <MenuItem onClick={handleClose}>Import</MenuItem>
                        <Box display={{ xs: 'inline', lg: 'none' }}>
                            <MenuItem onClick={handleClose}>New</MenuItem>
                        </Box>
                        <MenuItem onClick={handleClose}>Print</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            {
                (status === SearchStatus.Initializing || status === SearchStatus.Loading || status === SearchStatus.Updating) &&
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {
                (status === SearchStatus.Loaded || status === SearchStatus.Updating) &&
                <RSTable info={props.RSTableInfo} checkRowFunction={checkRow} checkAllRowsFunction={checkAllRows}></RSTable>
            }
        </div >
    );
}
import axios from 'axios';
import config from 'Config';
import Cookies from 'js-cookie';

const token = Cookies.get(config.REACT_APP_RTokenCookieKey);

const request = axios.create({
    baseURL: config.RestConfigApi.APIBaseUrl,
    timeout: 1000,
    headers: {
        'R-Token' : token,
    }
});

export default request;
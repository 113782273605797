import React from 'react';
import { useSelector } from 'Utils/useSelector';
import {
    Grid,
    TextField,
    Theme,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface dropDownValues {
    value: number;
    title: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: 'center',
        },
        center: {
            alignItems: 'center',
        },
        text: {
            fontWeight: 'bold',
        }
    }),
);

type resultsProps = {
    updateResults: Function,
    resultsValue: number,
};

const ResultsPerPage: React.FC<resultsProps> = (props) => {
    const classes = useStyles();
    const { updateResults, resultsValue } = props;

    const pageListingSize = [
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value:  100,
        },
        {
            label: '150',
            value: 150,
        },
        {
            label: '200',
            value: 200,
        },
        {
            label: '250',
            value: 250,
        },
        {
            label: '300',
            value: 300,
        },
    ];

    let initialFormValues: dropDownValues = {
        value: 50,
        title: '50',
    }


    function productTypeDropDown() {
        return (
            <Autocomplete
                id="result-per-page"
                options={ pageListingSize }
                getOptionLabel={ option => option.label }
                onChange={ (event, item) => {
                    updateResults(item);
                }}
                value={{ label: resultsValue.toString(), value: resultsValue }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label=""
                            variant="outlined"
                            name="listing_per_page"
                            size="small"
                            required
                        />);
                    }
                }
            />
        );
    }

    return (
        <Grid className={ classes.center } container>
            <Grid xs={ 7 } className={ classes.text } item>
                Results Per Page
            </Grid>
            <Grid xs={ 5 } item>
                { productTypeDropDown() }
            </Grid>
        </Grid>
    );
};

export default ResultsPerPage;
import React, { useEffect } from 'react';
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    TextField,
    makeStyles,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    SwipeableDrawer,
    Switch,
    FormControlLabel,
    List,
    ListItem,
    Divider,
    DialogTitle,
    IconButton,
    ListSubheader,
    TextFieldProps,
} from '@material-ui/core';
import { ProductsActions } from 'Actions/ProductsActions2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'Utils/useSelector';
import BoundTextField from 'Components/TextFieldExtended/TextFieldExtended';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { ExtendedProp } from 'Utils/CommonJS';
import { default as ProductsModel } from '@readystock-javascript/common-library/lib/Models/RestAPI/Products';
import RSTable, { RSTableInfoColumn, RSTableInfo } from 'Components/RSTable';
import { SearchStatus, SearchActions, SearchState } from 'Actions/SearchActions';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { getParams } from 'Utils/CommonJS';
import { useHistory } from 'react-router-dom';

export class Search2Props {
    public RecordTypeName!: string;
    public RecordTypeNamePlural!: string;
    public EditUrl!: string;
    public RSTableInfo!: RSTableInfo;
    public FilterElements?: JSX.Element;
    public MoreFilters?: JSX.Element;
    public SearchActions!: SearchActions;
    public SearchState!: SearchState;
}

export default function Searchs2(props: Search2Props) {

    /*
     * Declare Variables
     */

    let history = useHistory();
    let dispatch = useDispatch();
    let urlParams = getParams(window.location.href) as { variant?: string };

    /*
     * Styles
     */

    let styles = makeStyles((theme) => ({
        cssLabel: {},

        cssOutlinedInput: {
            '&$cssFocused $notchedOutline': {
                borderColor: `#0095FF !important`,
            }
        },

        cssFocused: {},

        notchedOutline: {}
    }));
    let inputClasses = styles();

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: '100%',

        },
        content: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        }
    }));
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    /*
     * Components
     */

    let MyTextField = (props: { label: string, width: string }) => {
        return (
            <TextField variant="outlined"
                margin="none"
                label={props.label}
                size="small"
                InputLabelProps={{
                    classes: {
                        root: inputClasses.cssLabel,
                        focused: inputClasses.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: inputClasses.cssOutlinedInput,
                        focused: inputClasses.cssFocused,
                        notchedOutline: inputClasses.notchedOutline,
                    },
                    inputMode: "text"
                }}
                style={{ width: props.width, marginRight: ".5em" }} />
        )
    };

    let autocompleteExtendInputParams = function (params: TextFieldProps): TextFieldProps {
        return {
            ...params,
            InputLabelProps: {
                ...params.InputLabelProps,
                classes: {
                    root: ExtendedProp(params.InputLabelProps, 'classes.root') + ' ' + inputClasses.cssLabel,
                    focused: ExtendedProp(params.InputLabelProps, 'classes.focused') + ' ' + inputClasses.cssFocused,
                },
            },
        }
    }

    let dropDownExample: { Label: string }[] = [
        {
            Label: "Test 1"
        },
        {
            Label: "Test 2"
        },
        {
            Label: "Test 3"
        },
        {
            Label: "Test 4"
        }
    ];

    let MyAutocomplete = (params: {
        label: string,
        fullWidth: boolean,
        width?: string
    }) => {
        let autocompleteParams = params;

        return (
            <Autocomplete
                options={dropDownExample}
                getOptionLabel={(option) => option.Label}
                style={{
                    width: autocompleteParams.width ?? "90%",
                    display: "inline-block",
                    marginRight: ".5em"
                }}
                renderInput={(params) => <TextField {...autocompleteExtendInputParams(params)} label={autocompleteParams.label} variant="outlined" fullWidth={autocompleteParams.fullWidth} margin="none" size="small" />}
            />
        );
    };

    let FullButton = (props: { label: string, onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) => {
        return (
            <FormControl size="small">
                <Button onClick={props.onClick} variant="contained" color="primary" size="small" style={{ padding: "8.5px 8px", marginRight: ".5em" }}>{props.label}</Button>
            </FormControl>
        );
    }

    /*
     * CallBack Functions
     */

    let addRecord = () => {
        history.push(props.EditUrl);
    }

    let checkRow = (RowKey: string) => {
        dispatch(props.SearchActions.CheckRow(RowKey));
    }

    let checkAllRows = () => {
        dispatch(props.SearchActions.CheckAllRows());
    }

    let toggleMoreFilters = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch(props.SearchActions.ToggleMoreFilters());
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item sm={4}>
                    <h1>{props.RecordTypeNamePlural}</h1>
                </Grid>
                <Grid item sm={8} style={{ textAlign: "right" }}>
                    <FullButton label={"Add " + props.RecordTypeName} onClick={(e) => addRecord()}></FullButton>
                    <FullButton label="Import" onClick={(e) => alert('test')}></FullButton>
                    <FullButton label="Export" onClick={(e) => alert('test')}></FullButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={10}>
                    <FormControl variant="outlined" style={{ width: "10em" }} size="small">
                        <InputLabel id="SearchView">View</InputLabel>
                        <Select labelId="SearchView" onChange={() => void (0)} label="View">
                            <ListSubheader>Category 1</ListSubheader>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                            <ListSubheader>Category 2</ListSubheader>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="InStock">In Stock</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2} style={{ textAlign: "right" }}>
                    <FormControl variant="outlined" style={{ width: "10em" }} size="small">
                        <InputLabel id="PageRows">Rows</InputLabel>
                        <Select labelId="PageRows" onChange={() => void (0)} label="Rows">
                            <MenuItem value="50">50</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                            <MenuItem value="250">250</MenuItem>
                            <MenuItem value="500">500</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box p={3} className={classes.content}>
                {props.FilterElements}
                {(props.MoreFilters !== undefined) &&
                    <FullButton label="More Filters" onClick={toggleMoreFilters}></FullButton>
                }

                <FormControl style={{ float: "right", width: "9em" }} variant="outlined" size="small">
                    <InputLabel id="Columns">Columns</InputLabel>
                    <Select multiple
                        MenuProps={MenuProps}
                        onChange={() => void (0)}
                        labelId="Columns"
                        label="Columns"
                        value={[]}>
                        <MenuItem value="Column1">Column1</MenuItem>
                        <MenuItem value="Column2">Column2</MenuItem>
                        <MenuItem value="Column3">Column3</MenuItem>
                        <MenuItem value="Column4">Column4</MenuItem>
                    </Select>
                </FormControl>
                {
                    (props.SearchState.Status === SearchStatus.Loaded || props.SearchState.Status === SearchStatus.Updating) &&
                    <div style={{ marginTop: "1em" }}>
                        <RSTable info={props.RSTableInfo} checkRowFunction={checkRow} checkAllRowsFunction={checkAllRows}></RSTable>
                    </div>
                }
                {
                    props.RSTableInfo && props.RSTableInfo.Rows.length === 0 &&
                    <div>
                        <h4 style={{ textAlign: "center", padding: "2em 0em" }}>No results</h4>
                    </div>
                }
                <SwipeableDrawer style={{ zIndex: 1500 }} anchor="right" open={props.SearchState.ShowMoreFilters} onOpen={toggleMoreFilters} onClose={toggleMoreFilters}>
                    <DialogTitle disableTypography>
                        <h2>More Filters
                            <IconButton onClick={toggleMoreFilters} style={{ float: "right", position: "relative", top: "-.5em" }} size="small">
                                <CancelIcon />
                            </IconButton>
                        </h2>
                    </DialogTitle>
                    <Divider style={{ color: "black", width: "100%" }} />
                    <div style={{ width: 250 }} role="presentation">
                        {props.MoreFilters}
                    </div>
                </SwipeableDrawer>
            </Box>
            <Pagination count={10} page={2} style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px" }} />
        </div>
    )
}
import request from 'shared/request';
import { barcodeRequested, barcodeSuccess, barcodeFailed } from 'data/Barcode/actions/actions';

export function fetchBarcode(parameters = {}) {
    return dispatch => {
        dispatch(barcodeRequested());
        request.get('barcodes', { params: { ...parameters }})
        .then(data => {
            dispatch(barcodeSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(barcodeFailed(error));
            return error;
        });
    }
}
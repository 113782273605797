import { Record, Map } from 'immutable';

const BaseRecord = defaultValues => class extends Record({
    loading: false,
    loaded: false,
    error: null,
    received: 0,
    items: new Map(),
    ...defaultValues,
}) {

};

export default BaseRecord;
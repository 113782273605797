import { PRODUCT_REQUEST, PRODUCT_SUCCESS, PRODUCT_FAILED } from 'shared/state/action-type';

import { Product } from 'data/Product/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function ProductReducer (state = new BaseState(), action) {
    switch (action.type) {
        case PRODUCT_FAILED:
            return state.merge({
                loading: false,
                loaded: false,
                error: action.error,
            });
        case PRODUCT_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case PRODUCT_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(data => {
                    return [data.productId, new Product(data)];
                })),
             });
        default:
            return state;
    }
}
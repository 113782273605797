import React, { useEffect, useContext } from 'react';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';

import { getBarcodeTypeState } from 'data/BarcodeType/selectors/index';
import { saveVariant, updateVariant } from 'data/Variant/actions/saveVariant';
import { getVariantById } from 'data/Variant/selectors/index';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Grid,
    TextField,
    Button,
    Dialog,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useFormik } from 'formik';

interface barcodeTypes {
    barcodeTypeId: number,
    name: string,
    description: string,
}

interface variantEditValues {
    productId: number,
    sku: string,
    name: string,
    description: string,
    barcode: string,
    cost: string,
    price: string,
    weight: string,
    unitLabel: string,
    unitValue: string,
    barcodeTypeId: number,
    barcodeTypeTitle: string,
}

type VariantEdit = {
    variantId: number|null,
    productId: number,
    isOpen: boolean,
    triggerOpen: Function,
    triggerClose: Function,
};

const VariantEdit: React.FC<VariantEdit> = (props) => {
    const {
        variantId,
        productId,
        isOpen,
        triggerOpen,
        triggerClose,
    } = props;
    const muiGlobal = globalMui();

    const [barcodeTypeIsRequired, setBarcodeTypeIsRequired] = React.useState(false);
    const barcodeTypes = useSelector(state => getBarcodeTypeState(state));
    const variant = useSelector(state => getVariantById(variantId)(state));

    const dispatch = useDispatch();

    let initialFormValues: variantEditValues = {
        productId: productId,
        sku: '',
        name: '',
        description: '',
        barcode: '',
        cost: '',
        price: '',
        weight: '',
        unitLabel: '',
        unitValue: '',
        barcodeTypeId: 0,
        barcodeTypeTitle: '',
    }

    if (variant.size > 0) {
        const displayVariant = variant.get(variantId);

        initialFormValues = {
            productId: productId,
            sku: displayVariant.sku,
            name: displayVariant.name,
            description: displayVariant.description,
            barcode: '',
            cost: '',
            price: '',
            weight: '',
            unitLabel: '',
            unitValue: '',
            barcodeTypeId: 0,
            barcodeTypeTitle: '',
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialFormValues
        },
        onSubmit: (values: variantEditValues) => {
            // : values.cost
            // : values.price
            // : values.unitLabel
            // : values.unitValue
            // : values.weight

            if (!variantId) {
                const data = {
                    variant:  new Array({
                        productId: values.productId,
                        description: values.description,
                        name: values.name,
                        sku: values.sku,
                    }),
                    barcode: new Array({
                        variantId: null,
                        productId: values.productId,
                        barcodeTypeId: values.barcodeTypeId,
                        barcode: values.barcode,
                    })
                };

                dispatch(saveVariant(data))
                formik.resetForm();
                triggerClose();

                return;
            }

            const data = {
                variant:  new Array({
                    variantId: variantId,
                    productId: values.productId,
                    description: values.description,
                    name: values.name,
                    sku: values.sku,
                }),
                barcode: new Array({
                    variantId: variantId,
                    productId: values.productId,
                    barcodeTypeId: values.barcodeTypeId,
                    barcode: values.barcode,
                })
            };

            dispatch(updateVariant(data))
            formik.resetForm();
            triggerClose();
        },
    });

    function barcodeTypesDropdown() {

        const barcodeTypeOptions = barcodeTypes.map((barcode: barcodeTypes) => {
            return {
                label: barcode.name,
                value: barcode.barcodeTypeId
            };
        }).toList().toArray() ?? [];

        return (
            <Autocomplete
                id="barcode-type-id"
                options={ barcodeTypeOptions }
                getOptionLabel={ option => option.label }
                onChange={ (event, prod) => {
                    formik.setFieldValue('barcodeTypeId', prod ? prod.value : null)
                    formik.setFieldValue('barcodeTypeTitle', prod ? prod.label : null)
                }}
                value={{ label: formik.values.barcodeTypeTitle, value: formik.values.barcodeTypeId }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Barcode Type"
                            variant="outlined"
                            name="barcode_types"
                            required={ barcodeTypeIsRequired }
                        />);
                    }
                }
            />
        );
    }

    const checkTypesReqired = (input: string) => {
        if (barcodeTypeIsRequired && input === '') {
            setBarcodeTypeIsRequired(false);
            return;
        }

        if (!barcodeTypeIsRequired && input.length > 0) {
            setBarcodeTypeIsRequired(true);
        }
    }

    function units() {

        const units = [
           { label: 'lbs', value: 'lbs' }
        ];

        return (
            <Autocomplete
                id="weight"
                options={ units }
                getOptionLabel={ option => option.label }
                onChange={ (event, prod) => {
                    formik.setFieldValue('unitLabel', prod ? prod.value : null)
                    formik.setFieldValue('unitValue', prod ? prod.label : null)
                }}
                value={{ label: formik.values.unitLabel, value: formik.values.unitValue }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Weight Unit"
                            variant="outlined"
                            name="weight"
                        />);
                    }
                }
            />
        );
    }

    return (
        <div>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 8 }>
                    <h3 className={ muiGlobal.noPnoM }>Variants</h3>
                </Grid>
                <Grid item xs={ 4 } className={ muiGlobal.right }>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={ triggerOpen() }>
                        Add Variant
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={ isOpen }
                onClose={ triggerClose() }
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={ muiGlobal.paper }>
                    <h2>Add Variant</h2>
                    <form onSubmit={ formik.handleSubmit }>
                        <Grid container spacing={ 2 }>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="sku"
                                    name="sku"
                                    label="SKU"
                                    value={ formik.values.sku }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={ formik.values.name }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={ formik.values.description }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="barcode"
                                    name="barcode"
                                    label="Barcode"
                                    value={ formik.values.barcode }
                                    onChange={ formik.handleChange }
                                    onInput={ e => checkTypesReqired(formik.values.barcode) }
                                    onBlur={ e => checkTypesReqired(formik.values.barcode) }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                { barcodeTypesDropdown() }
                            </Grid>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="cost"
                                    name="cost"
                                    label="Cost"
                                    value={ formik.values.cost }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="price"
                                    name="price"
                                    label="Price"
                                    value={ formik.values.price }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="weight"
                                    name="weight"
                                    label="Weight"
                                    value={ formik.values.weight }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                { units() }
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} item></Grid>
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={ triggerClose() }
                                >
                                    Close
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default VariantEdit;
import React, { useEffect } from 'react';
import {
    RSTableInfoColumn, RSTableInfo
} from 'Components/RSTable';
import { default as VendorsModel } from '@readystock-javascript/common-library/lib/Models/RestAPI/Vendors';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';
import { VendorsActions } from 'Actions/VendorsActions';
import Search2 from 'Components/Search/Search2';
import { Search2Props } from 'Components/Search/Search2';
import { restClient } from 'Utils/ApiClient';

export default function Vendors() {

    let actions = new VendorsActions();
    let dispatch = useDispatch();
    let vendorsState = useSelector(state => state.Vendors)

    let Vendors = useSelector<VendorsModel[]>(state => state.Vendors.Vendors);
    useEffect(() => {
        restClient.Vendors.GetVendors().then((data: VendorsModel[]) => {
            dispatch(new VendorsActions().LoadVendors(data));
        });
    }, [dispatch, vendorsState.UpdateRequestDate]);

    let columns: RSTableInfoColumn[] = [];
    columns[columns.length] = new RSTableInfoColumn({ Name: "VendorID", Label: "Vendor ID" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Name", Label: "Name" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Description", Label: "Description" });
    let RSTable = RSTableInfo.BuildRSTableInfo(Vendors, "VendorID", columns, vendorsState.TableRowState, vendorsState.TopCheckBoxIsChecked);

    let searchProps: Search2Props = {
        RecordTypeName: "Vendor",
        RecordTypeNamePlural: "Vendors",
        EditUrl: "/vendor/",
        RSTableInfo: RSTable,
        SearchActions: actions,
        SearchState: vendorsState
    }

    return (
        <Search2 {...searchProps} />
    );
}
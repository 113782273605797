import React, { useEffect } from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Layout from 'Components/Layout/index';
import { useCookies } from 'react-cookie';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'Reducers/index'
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@material-ui/core';
import AppTheme from './AppTheme';
import Config from 'Config'

import MessageHandler from 'Components/MessageHandler/UserMessageDisplay';

const App: React.FC = () => {
  let [cookies] = useCookies([Config.REACT_APP_RTokenCookieKey ?? '']);

  let rToken: string | null = cookies[Config.REACT_APP_RTokenCookieKey ?? ''];

  let authenticationIsRequired = (process !== null && process !== undefined && Config !== null && Config !== undefined && Config.REACT_APP_RTokenCookieKey != null && Config.REACT_APP_RTokenCookieKey !== undefined);
  let isAuthenticated: boolean = (rToken != null && rToken !== '');

  useEffect(function () {
    if (authenticationIsRequired)
      if (!isAuthenticated) {
        window.location.href = Config.REACT_APP_LoginBaseUrl ?? '';
      }
  }, [authenticationIsRequired, isAuthenticated]);

  if (!authenticationIsRequired || isAuthenticated) {

    const store = createStore(
      rootReducer,
      //need to fix this before going to prod
      composeWithDevTools(applyMiddleware(thunk)),
    );
    const theme = AppTheme();

    return (
      <Provider store={store}>
        <CookiesProvider>
          <ThemeProvider theme={ theme }>
            <Router>
              <div>
                <MessageHandler />
                <Layout />
              </div>
            </Router>
          </ThemeProvider>
        </CookiesProvider>
      </Provider>
    );
  }
  else
    return (
      <div></div>
    )
}

export default App;

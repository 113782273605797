import { APP_MESSAGE_SUCCESS, APP_MESSAGE_REMOVE } from 'shared/state/action-type';

export function appMessageRemove(id) {
    return {
        type: APP_MESSAGE_REMOVE,
        payload: id,
    }
}

export function appMessageSuccess(message) {
    return {
        type: APP_MESSAGE_SUCCESS,
        payload: message,
    };
}
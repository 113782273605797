import {
    SearchAction,
    SearchState,
    SearchActionType,
    SearchStatus
} from "Actions/SearchActions";
import { TableRowState } from "Components/RSTable";

const BinsReducer = function (state: SearchState, action: SearchAction): SearchState {
    let result: SearchState;

    switch (action.SearchType) {
        case SearchActionType.CheckRow:
            let currentRowState: TableRowState = TableRowState.Default();
            if (state.TableRowState !== null && state.TableRowState !== undefined)
                currentRowState = state.TableRowState[action.RowKey ?? ""];
            currentRowState = currentRowState ?? TableRowState.Default();

            result = {
                ...state
            };

            result.TableRowState[action.RowKey ?? ""] = {
                ...currentRowState,
                IsChecked: !currentRowState.IsChecked
            };

            break;
        case SearchActionType.CheckAllRows:
            result = {
                ...state,
                TopCheckBoxIsChecked: !state.TopCheckBoxIsChecked
            };

            let newTableRowState: { [id: string]: TableRowState } = {};
            for (let id in result.TableRowState) {
                newTableRowState[id] = {
                    ...result.TableRowState[id],
                    IsChecked: result.TopCheckBoxIsChecked
                }
            }
            result.TableRowState = newTableRowState;

            break;
        case SearchActionType.ToggleAdvancedFilters:
            result = {
                ...state,
                ShowAdvancedFilters: !state.ShowAdvancedFilters
            };
            break;
        case SearchActionType.SetMenuAnchorElement:
            result = {
                ...state,
                MenuAnchorElement: action.MenuAnchorElement ?? null
            }
            break;
        case SearchActionType.UpdateGrid:
            result = {
                ...state,
                Status: SearchStatus.Updating,
                UpdateRequestDate: action.UpdateRequestDate ?? null
            }
            break;
        case SearchActionType.ToggleMoreFilters:
            result = {
                ...state,
                ShowMoreFilters: !state.ShowMoreFilters
            };
            break;
        default:
            result = state;
            break;
    }
    return result;
};

export default BinsReducer;
import React from 'react';

import { getAppMessageState } from 'data/AppMessage/selectors/getAppMessageState';
import GatherErrors from 'Components/MessageHandler/GatherMessages';

import { useSelector } from 'react-redux';

import 'Components/MessageHandler/assets/css/errors.scss';

interface MessageInterface {
    id: string,
    message: string,
    level: string,
    deration: number,
}

const MessageHandler = () => {
    const messages = useSelector(state => getAppMessageState(state));
    function callErrors() {
        if (messages.size > 0) {
            return messages.map( (error: MessageInterface, key: string) => {
                return (
                    <GatherErrors
                        key={ key }
                        messageKey={ key }
                        message={ error.message ?? '' }
                        errorLevel={ error.level !== '' ? error.level : 'error'}
                    />
                );
            }).valueSeq();
        }
    }

    return (
        <div className="global-message-handler">
            { callErrors() }
        </div>
    );
}

export default MessageHandler;
import React from "react";
import { Grid, Card, CardContent, makeStyles } from "@material-ui/core";
import queryString from 'query-string'
import testNames from 'TestData/TestNames.json';
import linq from 'linq'

const useStyles = makeStyles({
    CardGrid: {
        padding: ".5em",
        display: "grid",
    },
    OutOfOfficeCard: {
        opacity: .6
    }
});

enum StatusTypes {
    Picking,
    BinMove,
    Receiving,
    Shipping,
    NotInOffice
}

export default function UserStatuses() {
    let styles = useStyles();
    const value = queryString.parse(window.location.search);

    let rand = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    let count: number = 12;
    if (value.Count !== null && value.Count !== "" && value.Count !== undefined)
        count = Number(value.Count);

    let statusOptions: StatusTypes[] = [StatusTypes.Picking, StatusTypes.BinMove, StatusTypes.Receiving, StatusTypes.Shipping, StatusTypes.NotInOffice];

    let userStatuses: { status: string, firstName: string, lastName: string, type: StatusTypes, nextStatus: string, deviceName: string }[] = [];
    for (let i = 0; i < count; i++) {
        let name: string = testNames[i];
        let nameParts: string[] = name.split(' ');

        let statusType: StatusTypes = statusOptions[rand(0, statusOptions.length)];
        let status: string;
        let nextStatus: string = "";
        switch (statusType) {
            case StatusTypes.Shipping:
                status = "Shipping SO" + rand(1, 10000);
                nextStatus = "Shipping SO" + rand(1, 10000);
                break;
            case StatusTypes.Picking:
                status = "Picking SO" + rand(1, 10000);
                nextStatus = "Picking SO" + rand(1, 10000);
                break;
            case StatusTypes.Receiving:
                status = "Receiving PO" + rand(1, 10000);
                nextStatus = "Receiving PO" + rand(1, 10000);
                break;
            case StatusTypes.BinMove:
                status = "Moving Inventory";
                break;
            default:
                status = "Not Logged On"
                break;
        }

        if (nextStatus !== "")
            nextStatus = "Up Next: " + nextStatus;

        userStatuses[i] = {
            firstName: nameParts[0],
            lastName: nameParts[1],
            status: status,
            type: statusType,
            nextStatus: nextStatus,
            deviceName: "iOS #" + i
        };
    }

    return (
        <div>
            <h1>Worker Statuses</h1>
            <Grid container>
                {linq.from(userStatuses)
                    .orderBy(x => (x.type === StatusTypes.NotInOffice ? 2 : 1))
                    .thenBy(x => x.lastName)
                    .thenBy(x => x.firstName)
                    .toArray()
                    .map((userStatus, index) => {
                        let className: string = styles.CardGrid;
                        if (userStatus.type === StatusTypes.NotInOffice) {
                            className = [styles.CardGrid, styles.OutOfOfficeCard].join(' ');
                        }

                        return (
                            <Grid item md={3} sm={6} xs={12} className={className} key={("UserStatus" + index)}>
                                <Card>
                                    <CardContent>
                                        <p>
                                            <span style={{ fontWeight: "bold" }} className="FullName">{userStatus.firstName} {userStatus.lastName}</span>
                                            {userStatus.type !== StatusTypes.NotInOffice &&
                                                <span className="DeviceName"> on {userStatus.deviceName}</span>
                                            }
                                        </p>
                                        <p className="UserStatus">{userStatus.status}</p>
                                        <p style={{ fontStyle: "italic" }}>{userStatus.nextStatus}</p>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}
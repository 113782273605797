import { Record } from 'immutable';

export class Bin extends Record({
    binId: null,
    warehouseId: null,
    name: '',
    description: '',
    createdBy: null,
    dateCreated: '',
    updatedBy: null,
    dateUpdated: '',
}) {
}

import React, { useEffect } from 'react';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';

import { getBinById } from 'data/Bin/selectors/getBinState';
import { fetchBin } from 'data/Bin/actions/fetchBin';
import { saveBin, updateBin } from 'data/Bin/actions/saveBin';
import { fetchWarehouse } from 'data/Warehouse/actions/fetchWarehouse';
import { getWarehouseState } from 'data/Warehouse/selectors/getWarehouseState';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';
import SharedBreadcrumbs from 'shared/SharedBreadcrumbs';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

interface binEditValues {
    binId: number | null,
    warehouseId: number,
    warehouseName: string,
    name: string,
    description: string,
}

interface warehouseTypes {
    warehouseId: number,
    name: string,
    description: string,
}

const BinEdit = () => {
    const { binId } = useParams();
    const muiGlobal = globalMui();
    const history = useHistory();

    const bId = parseInt(binId);

    const bin = useSelector(state => getBinById(bId)(state));
    const warehouseListing = useSelector(state => getWarehouseState(state));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBin(bId));
        dispatch(fetchWarehouse());
    }, [dispatch]);

    let initialFormValues: binEditValues = {
        binId: null,
        warehouseId: 0,
        warehouseName: '',
        name: '',
        description: '',
    }

    if(bin.size !== 0) {
        const displayBin = bin.get(bId);
        const warehouse = warehouseListing.get(displayBin.warehouseId);
        let warehouseName = '';

        if (warehouse) {
            warehouseName = warehouse.name
        }

        initialFormValues = {
            binId: displayBin.binId,
            warehouseId: displayBin.warehouseId,
            warehouseName: warehouseName,
            name: displayBin.name,
            description: displayBin.description,
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialFormValues
        },
        onSubmit: (values: binEditValues) => {
            if (!bId) {
                const data = new Array({
                    warehouseId: values.warehouseId,
                    name: values.name,
                    description: values.description,
                });

                //@ts-ignore
                dispatch(saveBin(data)).then(binData => {
                    if (binData) {
                        const newBinId = binData.data[0].binId;
                        if (newBinId) {
                            dispatch(appMessageSuccess([{ message: 'Bin added.', level: 'success' }]));
                            history.push(`/bin/${newBinId}`);
                        }
                    }
                });
            } else {
                const data = new Array({
                    binId: bId,
                    warehouseId: values.warehouseId,
                    name: values.name,
                    description: values.description,
                });

                dispatch(updateBin(data));
            }
        },
    });

    function warehouseDropdown() {
        const dropdownOptions = warehouseListing.map((warehouse: warehouseTypes) => {
            return {
                label: warehouse.name,
                value: warehouse.warehouseId
            };
        }).toList().toArray() ?? [];

        return (
            <Autocomplete
                id="warehouse-id"
                options={ dropdownOptions }
                getOptionLabel={ option => option.label }
                onChange={ (event, prod) => {
                    formik.setFieldValue('warehouseId', prod ? prod.value : null)
                    formik.setFieldValue('warehouseName', prod ? prod.label : null)
                }}
                value={{ label: formik.values.warehouseName, value: formik.values.warehouseId }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Warehouse"
                            variant="outlined"
                            name="warehouse"
                            required
                        />);
                    }
                }
            />
        );
    }

    const clearForm = () => {
        formik.resetForm();
    }

    return (
        <div>
            { !!bId && bin.size > 0 &&
                <SharedBreadcrumbs
                    display={ !!bId }
                    links={{ 1: { link: '/bins', name: 'Bin' }}}
                    last={ bin.get(bId).name }
                />}
            <h1>{ !binId && <span>Add Bin</span> }</h1>
            <div className={ muiGlobal.paperMorePadding }>
                <div>
                    <form onSubmit={ formik.handleSubmit }>
                        <Grid container spacing={ 2 }>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="Name"
                                    name="name"
                                    label="name"
                                    value={ formik.values.name }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={ formik.values.description }
                                    onChange={ formik.handleChange }
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={ 4 }
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                { warehouseDropdown() }
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} item></Grid>
                            <Grid item >
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={ clearForm }
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BinEdit;
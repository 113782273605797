import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBin } from 'data/Bin/actions/fetchBin';
import { getBinState } from 'data/Bin/selectors/getBinState';
import ResultsPerPage from 'shared/ResultsPerPage';
import globalMui from 'shared/globalMaterialUiClasses';

import { useHistory } from 'react-router-dom';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quantity: {
            textAlign: 'center',
        },
        quantityList: {
            paddingLeft: '35px!important' as any,
            textAlign: 'center',
        },
        maxHeight: {
            maxHeight: '75vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: '0 -9px',
        },
        header: {
            alignItems: 'center',
            padding: "0 30px",
        },
        grow: {
            flexGrow: 1,
        },
        end: {
            justifySelf: 'flex-end',
            paddingTop: '20px',
        },
        arrowParent: {
            position: 'relative',
            textAlign: 'center',
        },
        arrow: {
            position: "absolute",
            right: '5px',
            top: '35%',
            color: '#999999',
            fontSize: '13px',
        },
        listingTitle: {
            borderBottom: '1px solid #E0E0E0',
            fontWeight: 'bold',
            marginBottom: '3px',
            padding: '0 11px 0 0',
        },
        relative: {
            position: "relative",
        },
        spaceBetween: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
);

interface BinListing {
    binId: number,
    name: string,
    description: string,
}

interface changeResult {
    value: number,
    label: string,
}

const BinListing = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const muiGlobal = globalMui();

    const [pageSize, setPageSize] = React.useState(50);

    const binListing = useSelector(state => getBinState(state));

    useEffect(() => {
        dispatch(fetchBin());
    }, [dispatch]);

    const editExisting = (key: number) => () => {
        history.push(`bin/${key}`);
    }

    const changePageSize = (selection: changeResult) => {
        setPageSize(selection.value);
    }

    const buildList = () => {
        if (binListing.size === 0) {
            return (<Grid xs={ 12 } className={ muiGlobal.missingText } item>Bins have not been added.</Grid>);
        }

        return binListing.map((bin: BinListing, key: number) => {

            return (
                <Grid
                    container
                    spacing={ 2 }
                    key={ key }
                    className={ muiGlobal.listing }
                    onClick={ editExisting(key) }
                >
                    <Grid xs={ 2 } item>
                        { bin.binId }
                    </Grid>
                    <Grid xs={ 5 } item>
                        { bin.name }
                    </Grid>
                    <Grid xs={ 5 } className={ muiGlobal.arrowRelative } item>
                        { bin.description }
                        <ArrowForwardIosIcon className={ classes.arrow } />
                    </Grid>
                </Grid>
            );
        }).valueSeq();
    }

    const addBin = () => {
        history.push('/bin/');
    }

    return (
        <div className={ classes.relative } >
            <Grid container className={ classes.spaceBetween }>
                <Grid xs={ 10 } item>
                    <h1>Bins</h1>
                </Grid>
                <Grid item>
                    <Button
                        onClick={ addBin }
                        variant="contained"
                        color="primary"
                        size="small">
                            Add Bin
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={ 9 } item></Grid>
                <Grid className={ classes.end } xs={ 3 } item>
                    <ResultsPerPage
                        updateResults={ changePageSize }
                        resultsValue={ pageSize }
                    />
                </Grid>
            </Grid>
            <div className={ muiGlobal.paperListing }>
                <Grid container spacing={ 2 } className={ classes.listingTitle }>
                    <Grid xs={ 2 } item>
                        Id
                    </Grid>
                    <Grid xs={ 5 } item>
                        Name
                    </Grid>
                    <Grid xs={ 5 } item>
                        Description
                    </Grid>
                </Grid>
                <div className={ classes.maxHeight }>
                    { buildList() }
                </div>
            </div>
        </div>
    );
}

export default BinListing;
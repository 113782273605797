import React from 'react';

import { appMessageRemoveAction } from 'data/AppMessage/actions/removeAppMessage';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

interface Errors {
    messageKey: string,
    message: string,
    errorLevel: string,
}

const GatherMessages: React.FC<Errors> = ({ messageKey, message, errorLevel }) => {
    const [show, setShow] = React.useState(true);
    const timeOut = 5000;
    const dispatch = useDispatch();

    function remove() {
        dispatch( appMessageRemoveAction(messageKey) );
    }

    setTimeout(() => {
        remove();
    }, timeOut);

    const closeOnClick = () => {
        remove();
    }

    if (show) {
        return (
            <section className={ classNames('message-handler', errorLevel) }>
                <div className={ classNames(errorLevel, 'level') }></div>
                <div className="message">{ message }</div>
                <button className="close" onClick={ closeOnClick }>x</button>
            </section>
        );
    }

    return null;
}

export default GatherMessages;
import { Record } from 'immutable';

export class Warehouse extends Record({
    warehouseId: null,
    addressId: null,
    name: '',
    description: '',
    createdBy: null,
    updatedBy: null,
    dateCreated: '',
    dateUpdated: '',
}) {
}

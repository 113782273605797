import { BIN_REQUEST, BIN_SUCCESS, BIN_FAILED } from 'shared/state/action-type';

import { Bin } from 'data/Bin/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function BinReducer (state = new BaseState(), action) {
    switch (action.type) {
        case BIN_FAILED:
            return state.merge({
                loading: false,
                loaded: false,
                error: action.error,
            });
        case BIN_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case BIN_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(data => {
                    return [data.binId, new Bin(data)];
                })),
             });
        default:
            return state;
    }
}
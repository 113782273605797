import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0095FF'
        }
    }
});

let AppTheme = () => theme;

export default AppTheme;
export const APP_MESSAGE_SUCCESS = 'APP_MESSAGE_SUCCESS';
export const APP_MESSAGE_REMOVE = 'APP_MESSAGE_REMOVE';

export const BAR_CODE_TYPES_REQUEST = 'BAR_CODE_TYPES_REQUEST';
export const BAR_CODE_TYPES_SUCCESS = 'BAR_CODE_TYPES_SUCCESS';
export const BAR_CODE_TYPES_FAILED = 'BAR_CODE_TYPES_FAILED';

export const BAR_CODE_REQUEST = 'BAR_CODE_REQUEST';
export const BAR_CODE_SUCCESS = 'BAR_CODE_SUCCESS';
export const BAR_CODE_FAILED = 'BAR_CODE_FAILED';

export const BIN_REQUEST = 'BIN_REQUEST';
export const BIN_SUCCESS = 'BIN_SUCCESS';
export const BIN_FAILED = 'BIN_FAILED';

export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILED = 'PRODUCT_FAILED';

export const PRODUCT_TYPES_REQUEST = 'PRODUCT_TYPES_REQUEST';
export const PRODUCT_TYPES_SUCCESS = 'PRODUCT_TYPES_SUCCESS';
export const PRODUCT_TYPES_FAILED = 'PRODUCT_TYPES_FAILED';

export const VARIANT_REQUEST = 'VARIANT_REQUEST';
export const VARIANT_SUCCESS = 'VARIANT_SUCCESS';
export const VARIANT_FAILED = 'VARIANT_FAILED';

export const WAREHOUSE_REQUEST = 'WAREHOUSE_REQUEST';
export const WAREHOUSE_SUCCESS = 'WAREHOUSE_SUCCESS';
export const WAREHOUSE_FAILED = 'WAREHOUSE_FAILED';
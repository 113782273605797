import { Record } from 'immutable';

export class Variant extends Record({
    saving: false,
    variationId: null,
    productId: null,
    sku: '',
    title: '',
    description: '',
    name: '',
    createdBy: null,
    dateCreated: '',
    updateBy: null,
    dateUpdated: '',
}) {

}
import {
    VendorsState,
    VendorsAction,
    VendorsActionType,
    DefaultVendorsState
} from "Actions/VendorsActions";
import { SearchStatus } from "Actions/SearchActions";
import SearchReducer from './SearchReducer';
import { TableRowState } from "Components/RSTable";

const VendorsReducer = function (state: VendorsState = DefaultVendorsState, action: VendorsAction): VendorsState {
    let searchState = SearchReducer(state, action);

    let result: VendorsState;
    switch (action.type) {
        case VendorsActionType.Initializing:
            result = {
                ...state,
                Status: SearchStatus.Initializing,
                Vendors: []
            };
            break;
        case VendorsActionType.Loaded:
            result = {
                ...state,
                Status: SearchStatus.Loaded,
                Vendors: action.Vendors ?? [],
                TableRowState: TableRowState.DefaultFromList(action.Vendors ?? [], 'VendorID')
            };
            break;
        case VendorsActionType.ToggleAdvancedFilters:
        case VendorsActionType.SetMenuAnchorElement:
        case VendorsActionType.UpdateGrid:
        case VendorsActionType.CheckRow:
        case VendorsActionType.CheckAllRows:
            result = {
                ...state,
                ...searchState
            }
            break;
        default:
            return state;
    }

    return result;
};

export default VendorsReducer;
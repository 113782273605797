import {
    ProductTypesState,
    DefaultProductTypesState,
    ProductTypesActionEnum,
    ProductTypesAction
} from 'Actions/ProductTypesActions';

const ProductTypesReducer = (State: ProductTypesState = DefaultProductTypesState, Action: ProductTypesAction): ProductTypesState => {
    switch (Action.type) {
        case ProductTypesActionEnum.Loaded:
            return {
                ProductTypes: Action.ProductTypes
            };
        default:
            return State;
    }
}

export default ProductTypesReducer;
import { combineReducers, Reducer } from 'redux';
import ProductsReducerRemove from './ProductsReducer2';
import { ProductsState } from 'Actions/ProductsActions2';
import { ProductTypesState } from 'Actions/ProductTypesActions';
import ProductTypesReducer from 'Reducers/ProductTypes';
import { LayoutState } from 'Actions/Layout'
import LayoutReducer from './layout';
import { VendorsState } from 'Actions/VendorsActions';
import VendorsReducer from './VendorsReducer';
import { InventoriesState } from 'Actions/InventoriesActions';
import InventoriesReducer from './InventoriesReducer';
import { SalesState } from 'Actions/SalesActions';
import SalesReducer from './SalesReducer';
import { ProductState as ProductStateRemove } from 'Actions/ProductActions2';
import ProductReducer2 from './ProductReducer2'
import BarcodeTypeState from 'data/BarcodeType/state';
import BarcodeTypeReducer from 'data/BarcodeType/reducer';
import BarcodeState from 'data/Barcode/state';
import BarcodeReducer from 'data/Barcode/reducer';
import VariantState from 'data/Variant/state';
import VariantReducer from 'data/Variant/reducer';
import ProductState from 'data/Product/state';
import ProductReducer from 'data/Product/reducer';
import AppMessageState from 'data/AppMessage/state';
import AppMessageReducer from 'data/AppMessage/reducer';
import ProductTypeState from 'data/ProductType/state';
import ProductTypeReducer from 'data/ProductType/reducer';
import BinState from 'data/Bin/state';
import BinReducer from 'data/Bin/reducer';
import WarehouseState from 'data/Warehouse/state';
import WarehouseReducer from 'data/Warehouse/reducer';

export interface RootState {
    AppMessages: AppMessageState,
    Barcode: BarcodeState,
    BarcodeType: BarcodeTypeState
    Bin: BinState
    Inventories: InventoriesState
    LayoutState: LayoutState
    Product: ProductState
    ProductType: ProductTypeState
    ProductRemove: ProductStateRemove
    ProductTypes: ProductTypesState
    ProductsRemove: ProductsState
    Sales: SalesState
    Variant: VariantState
    Vendors: VendorsState
    Warehouse: WarehouseState
}

const rootReducer: Reducer<RootState> = combineReducers<RootState>({
    AppMessages: AppMessageReducer,
    Barcode: BarcodeReducer,
    BarcodeType: BarcodeTypeReducer,
    Bin: BinReducer,
    Inventories: InventoriesReducer,
    LayoutState: LayoutReducer,
    Product: ProductReducer,
    ProductType: ProductTypeReducer,
    ProductRemove: ProductReducer2,
    ProductTypes: ProductTypesReducer,
    ProductsRemove: ProductsReducerRemove,
    Sales: SalesReducer,
    Variant: VariantReducer,
    Vendors: VendorsReducer,
    Warehouse: WarehouseReducer,
});

export default rootReducer;
export interface EditState {
    SavedID: string | null;
    SavedAt?: number | null;
    Errors?: string[] | null;
}

export enum EditActionType {
    Saved,
    ClearMessage,
    SetErrors
}

export interface EditAction {
    EditActionType?: EditActionType
    Name?: string
    Value?: string
    SavedID?: string | null | undefined;
    Errors?: string[] | null;
}

export abstract class EditActions {
    public abstract UpdateRecordField(name: string, value: any): EditAction;
    public abstract Save(record: any): Promise<EditAction>;
    public abstract SaveAndNew(record: any): EditAction;
    public abstract Delete(): void;
    public abstract New(): EditAction;
    public abstract ClearMessage(): EditAction;
    public abstract SetErrors(errors: string[]): EditAction;
}
import React, { useEffect } from 'react';

import ProductListing from 'Components/Product/ProductListing';

import {
    AppBar,
    Tabs,
    Tab,
    Box,
    makeStyles,
    Theme,
    Grid,
    Button,
    Icon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#EDF7FF',
    },
    breadcrumbs: {
        padding: '10px 0',
    },
    indicator: {
        backgroundColor: 'white',
    },
    quantity: {
        textAlign: 'center',
    },
    quantityList: {
        paddingLeft: '35px!important' as any,
        textAlign: 'center',
    },
    maxHeight: {
        maxHeight: '75vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        margin: '0 -9px',
    },
    header: {
        alignItems: 'center',
        padding: "0 30px",
    },
    grow: {
        flexGrow: 1,
    },
    end: {
        justifySelf: 'flex-end',
    },
    arrowParent: {
        position: 'relative',
        textAlign: 'center',
    },
    arrow: {
        position: "absolute",
        right: '5px',
        top: '35%',
        color: '#999999',
        fontSize: '13px',
    },
    listingTitle: {
        borderBottom: '1px solid #E0E0E0',
        fontWeight: 'bold',
        marginBottom: '3px',
        padding: '0 11px 0 0',
    },
}));


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={ value !== index }
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { value === index && (
          <Box p={ 3 }>
            {children}
          </Box>
        )}
      </div>
    );
 }

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ProductListingTabs = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    let history = useHistory();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const addProduct = () => {
        history.push('/product/');
    }

    return (
        <div>
            <Grid container className={ classes.header }>
                <Grid className={ classes.grow } item>
                    <h1>Products</h1>
                </Grid>
                <Grid className={ classes.end } item>
                    <Button
                        onClick={ addProduct }
                        variant="contained"
                        color="primary"
                        size="small">
                            Add Product
                    </Button>
                </Grid>
            </Grid>
            <div className={ classes.root }>
                <AppBar
                    position="sticky"
                    color="transparent"
                    className={ classes.appBar } >
                    <Tabs
                        indicatorColor="primary"
                        value={ value }
                        onChange={ handleChange }
                        aria-label="Existing Product Detail View"
                    >
                        <Tab label="ALL" {...a11yProps(0)} />
                        <Tab label="IN STOCK" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={ value } index={ 0 }>
                    <ProductListing />
                </TabPanel>
                <TabPanel value={ value } index={ 1 }>
                    <div>there is where i would gahter data about something but i have no data to do that with</div>
                </TabPanel>
            </div>
        </div>
    );
}

export default ProductListingTabs;
import { WAREHOUSE_REQUEST, WAREHOUSE_SUCCESS, WAREHOUSE_FAILED } from 'shared/state/action-type';

import { Warehouse } from 'data/Warehouse/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function WarehouseReducer (state = new BaseState(), action) {
    switch (action.type) {
        case WAREHOUSE_FAILED:
            return state.merge({
                loading: false,
                loaded: false,
                error: action.error,
            });
        case WAREHOUSE_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case WAREHOUSE_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(data => {
                    return [data.warehouseId, new Warehouse(data)];
                })),
             });
        default:
            return state;
    }
}
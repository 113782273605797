import React, { useEffect, ReactElement } from 'react';
import {
    RSTableInfoColumn,
    RSTableInfo,
} from 'Components/RSTable';
import { SalesExtended } from '@readystock-javascript/common-library/lib/Models/RestAPI/Sales';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';
import { SalesActions } from 'Actions/SalesActions';
import Search from 'Components/Search/Search';
import { restClient } from 'Utils/ApiClient';

export default function Sales() {

    let salesActions = new SalesActions();
    let dispatch = useDispatch();
    let SalesState = useSelector(state => state.Sales)
    useEffect(() => {
        restClient.Sales.GetSales().then((data: SalesExtended[]) => {
            dispatch(new SalesActions().LoadSales(data))
        });
    }, [dispatch, SalesState.UpdateRequestDate]);

    let firstProducts = (props: { record: object, index: number }): JSX.Element => {
        let sale: SalesExtended = props.record as SalesExtended;
        return (
            <div key={"SaleLineInfo" + props.index}>
                {sale.SaleLines?.map((line, index) => {
                    if (index <= 2)
                        return (
                            <span key={"SalesSkuList" + index}>
                                {index > 0 &&
                                    <br />
                                }
                                {line.Variation.sku}
                            </span>
                        )
                })}
                {sale !== null && sale.SaleLines !== undefined && sale.SaleLines?.length > 3 &&
                    <span key={"SalesAdditionalMessage" + props.index}>
                        <br />+{(sale.SaleLines?.length - 3)} more
                    </span>
                }
            </div>
        );
    }

    let columns: RSTableInfoColumn[] = [];
    columns[columns.length] = new RSTableInfoColumn({ Name: "Sale.SaleID", Label: "Sale ID" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Sale.dateCreated", Label: "Date" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Sale.SaleNumber", Label: "Order #" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Sale.ShippingPerson.FirstName", Label: "First Name" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "Sale.ShippingPerson.LastName", Label: "Last Name" });
    columns[columns.length] = new RSTableInfoColumn({ Label: "Lines", ElementFunction: firstProducts });
    let RSTable = RSTableInfo.BuildRSTableInfo(SalesState.Sales, "Sale.SaleID", columns, SalesState.TableRowState, SalesState.TopCheckBoxIsChecked);
    //RSTable.OpenUrl = "/sale/";

    return (
        <Search RSTableInfo={RSTable} SearchActions={salesActions} SearchState={SalesState} />
    );
}
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from 'Components/Home';
import BinList from 'Components/Bin/BinList';
import BinEdit from 'Components/Bin/BinEdit';
import Vendors from 'Components/Vendors/Vendors';
import Inventory from 'Components/Inventory/Inventory';
import ProductListingTabs from 'Components/Product/ProductListingTabs';
import EditProduct from 'Components/Product/EditProduct';
import EditExistingProduct from 'Components/Product/EditExistingProduct';
import Sales from 'Components/Sales/Sales';
import UserStatuses from 'Components/UserStatuses/UserStatuses';
import WarehouseEdit from 'Components/Warehouse/WarehouseEdit';
import WarehouseListing from 'Components/Warehouse/WarehouseListing';

export default function Routes() {
    return (
        <Switch>
            <Route path="/bins">
                <BinList />
            </Route>
            <Route path={ ["/bin/:binId", "/bin/"] }>
                <BinEdit />
            </Route>
            <Route path="/inventory">
                <Inventory />
            </Route>
            <Route path="/product/:ID">
                <EditExistingProduct />
            </Route>
            <Route path="/product/">
                <EditProduct />
            </Route>
            <Route path="/products">
                <ProductListingTabs />
            </Route>
            <Route path="/vendors">
                <Vendors />
            </Route>
            <Route path="/warehouses">
                <WarehouseListing />
            </Route>
            <Route path={["/warehouse/:warehouseId", "/warehouse/"]}>
                <WarehouseEdit />
            </Route>
            <Route path="/sales">
                <Sales />
            </Route>
            <Route path="/Workers">
                <UserStatuses />
            </Route>
            <Route path="/">
                <Home></Home>
            </Route>
        </Switch>
    );
}
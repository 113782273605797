import request from 'shared/request';
import { variantRequested, variantSuccess, variantFailed } from 'data/Variant/actions/actions';

//https://docs.readystock.app/#variations
export function fetchVariant(parameters = {}) {
    return dispatch => {
        dispatch(variantRequested());
        request.get('variations', { params: { ...parameters }})
        .then(data => {
            dispatch(variantSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(variantFailed(error));
            return error;
        });
    }
}

export class MenuItem {
    public Label: string = ""
    public Url: string = ""

    public constructor(init?: Partial<MenuItem>) {
        Object.assign(this, init);
    }
};

export function Menu(): MenuItem[] {
    return [
        new MenuItem({ Label: "Home", Url: "/" }),
        new MenuItem({ Label: "Sales", Url: "/Sales" }),
        new MenuItem({ Label: "Products", Url: "/Products" }),
        new MenuItem({ Label: "Inventory", Url: "/Inventory" }),
        new MenuItem({ Label: "Bins", Url: "/Bins" }),
        new MenuItem({ Label: "Warehouses", Url: "/Warehouses" }),
        new MenuItem({ Label: "Vendors", Url: "/Vendors" }),
        new MenuItem({ Label: "Workers", Url: "/Workers" })
    ];
}
import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VariantEdit from 'Components/Variants/VariantEdit';
import { fetchVariant } from 'data/Variant/actions/fetchVariant';
import { fetchBarcode } from 'data/Barcode/actions/fetchBarcode';
import { getVariantState } from 'data/Variant/selectors/index';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    makeStyles,
    Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        maxHeight: {
            maxHeight: '65vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: '0 -9px',
        },
    }),
);

type VariantProductId = {
    productId?: number,
};

interface VariantListing {
    variationId: number,
    productId: number,
    sku: string,
    title: string,
    name: string,
    description: string,
}

const VariantListing: React.FC<VariantProductId> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const muiGlobal = globalMui();

    const { productId } = props;
    const variantList = useSelector(state => getVariantState(state));

    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [variantId, setVariantId] = React.useState(0);

    useEffect(() => {
        dispatch(fetchVariant({ "productId": productId }));
        dispatch(fetchBarcode({ "variantId": productId }));
    }, [dispatch]);

    const openEdit = (variantId = 0) => () => {
        setVariantId(variantId);
        setOpen(true);
    };

    const closeEdit = () => () => {
        setVariantId(0);
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }

    const displayCheckbox = () => {
        return (
            <Fragment>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={ checked }
                            onChange={ handleChange }
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Enable variations"
                />
            </Fragment>
        );
    }

    const buildList = () => {
        const missingVariant = (<Grid xs={ 12 } className={ muiGlobal.missingText } item>Variants have not been added.</Grid>);

        if (variantList.size === 0) {
            return missingVariant;
        }

        //this should move to a selector
        const variationsToProducts = variantList.filter((variantList: VariantListing) => variantList.productId === productId)

        if (variationsToProducts.size === 0) {
            return missingVariant;
        }

        return variationsToProducts.map((variation: VariantListing, key: number) => {

            return (
                <Grid container spacing={ 2 } key={ key } className={ muiGlobal.listing } onClick={ openEdit(key) }>
                    <Grid xs={ 3 } item>
                        { variation.sku }
                    </Grid>
                    <Grid xs={ 3 } item>
                        { variation.name }
                    </Grid>
                    <Grid xs={ 3 } item>
                        { variation.title }
                    </Grid>
                    <Grid xs={ 3 } item>
                        { variation.description }
                    </Grid>
                </Grid>
            );
        }).valueSeq();
    }

    if (variantList.size >= 1 && !checked) {
        if (variantList.size === 1) {
            const variantName = variantList.first();

            if (variantName.name) {
                setChecked(true);
            }
        }

        if (variantList.size > 1) {
            setChecked(true);
        }
    }

    //if checkbox is not checked
    if (!checked) {
        return (
            <div className={ muiGlobal.paper }>
                <h3 className={ muiGlobal.noPnoM }>Variants</h3>
                { displayCheckbox() }
            </div>
        );
    }

    return (
        <div className={ muiGlobal.paper }>
             <VariantEdit
                variantId={ (variantId !== 0 ? variantId : null) }
                productId={ (productId ? productId : 0) }
                isOpen={ open }
                triggerOpen={ openEdit }
                triggerClose={ closeEdit }
            />
            { displayCheckbox() }
            <div className={ muiGlobal.listingGeneral }>
                <Grid container spacing={ 2 } className={ muiGlobal.listingTitle }>
                    <Grid xs={ 3 } item>
                        SKU
                    </Grid>
                    <Grid xs={ 3 } item>
                        Name
                    </Grid>
                    <Grid xs={ 3 } item>
                        Title
                    </Grid>
                    <Grid xs={ 3 } item>
                        Description
                    </Grid>
                </Grid>
                <div className={ classes.maxHeight }>
                    { buildList() }
                </div>
            </div>
        </div>
    );
}

export default VariantListing;
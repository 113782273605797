export interface LayoutState {
    AccountMenuElement: Element | null;
};

export const DefaultLayoutState: LayoutState = {
    AccountMenuElement: null
};

export enum LayoutActionType {
    SetAccountMenuElement = 'LayoutActionType_SetAccountMenuElement'
}

export interface LayoutAction {
    type: LayoutActionType;
    AccountMenuElement: Element | null;
}

export function SetAccountMenuElement(AccountMenuElement: Element | null): LayoutAction {
    return {
        type: LayoutActionType.SetAccountMenuElement,
        AccountMenuElement: AccountMenuElement
    };
}
import React from 'react';
import UserStatuses from 'Components//UserStatuses/UserStatuses';
import { useParams } from 'react-router-dom';
import queryString from 'query-string'
import Config from 'Config';

export default function Home() {
    return (
        <div>
            <h1>Welcome to readystock!</h1>
        </div>
    );
}
import request from 'shared/request';
import { barcodeRequested, barcodeSuccess, barcodeFailed } from 'data/Barcode/actions/actions';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';


export function saveBarcode(parameters = {}) {
    return dispatch => {
        dispatch(barcodeRequested());
        request.post('barcodes', parameters)
        .then(data => {
            dispatch(barcodeSuccess(data));
            dispatch(appMessageSuccess([{ message: 'Barcode added.', level: 'success' }]));
            return data;
        })
        .catch(error => {
            dispatch(barcodeFailed(error));
            dispatch(appMessageSuccess([{ message: error, level: 'error' }]));
            return error;
        });
    }
}
import {
    SearchState,
    SearchActions,
    SearchAction,
    SearchStatus,
    SearchActionType
} from './SearchActions';
import Products from '@readystock-javascript/common-library/lib/Models/RestAPI/Products';

export enum ProductsActionType {
    Initializing = 'ProductsActionType_Initializing',
    Loading = 'ProductsActionType_Loading',
    Loaded = 'ProductsActionType_Loaded',
    LoadingFailed = 'ProductsActionType_LoadingFailed',
    ToggleAdvancedFilters = 'ProductsActionType_ToggleAdvancedFilters',
    SetMenuAnchorElement = 'ProductsActionType_SetMenuAnchorElement',
    UpdateGrid = 'ProductsActionType_RefreshGrid',
    CheckRow = 'ProductsActionType_CheckRow',
    CheckAllRows = 'ProductsActionType_CheckAllRows',
    SetTab = 'ProductsActionType_SetTab',
    MoreFilters = "ProductsActionType_MoreFilters"
}

export interface ProductsState extends SearchState {
    Products: Products[]
    TabIndex: number;
}

export const DefaultProductsState: ProductsState = {
    Status: SearchStatus.Initializing,
    MenuAnchorElement: null,
    ShowAdvancedFilters: false,
    Products: [],
    UpdateRequestDate: null,
    TableRowState: {},
    TopCheckBoxIsChecked: false,
    TabIndex: 0,
    ShowMoreFilters: false
};

export interface ProductsAction extends SearchAction {
    type: ProductsActionType;
    Products?: Products[];
    TabIndex?: number;
}

export class ProductsActions extends SearchActions {
    public RefreshGrid(): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.UpdateGrid,
            SearchType: SearchActionType.UpdateGrid,
            UpdateRequestDate: new Date(Date.now())
        }
        return result;
    }
    public CheckAllRows(): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.CheckAllRows,
            SearchType: SearchActionType.CheckAllRows
        };
        return result;
    }
    public ToggleAdvancedFilters(): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.ToggleAdvancedFilters,
            SearchType: SearchActionType.ToggleAdvancedFilters
        };
        return result;
    }
    public SetMenuAnchorElement(element: Element | null): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.SetMenuAnchorElement,
            MenuAnchorElement: element,
            SearchType: SearchActionType.SetMenuAnchorElement
        };
        return result;
    }
    public LoadProducts(Products: Products[]): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.Loaded,
            Products: Products
        };
        return result;
    }
    public CheckRow(key: string): SearchAction {
        let result: ProductsAction = {
            type: ProductsActionType.CheckRow,
            RowKey: key,
            SearchType: SearchActionType.CheckRow
        };
        return result;
    }
    public SetTab(index: number): ProductsAction {
        return {
            type: ProductsActionType.SetTab,
            TabIndex: index
        };
    }
    public ToggleMoreFilters(): ProductsAction {
        return {
            type: ProductsActionType.MoreFilters
        };
    }
}
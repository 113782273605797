import { BAR_CODE_TYPES_REQUEST, BAR_CODE_TYPES_SUCCESS, BAR_CODE_TYPES_FAILED } from 'shared/state/action-type';

import { BarcodeType } from 'data/BarcodeType/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function BarcodeTypeReducer (state = new BaseState(), action) {
    switch (action.type) {
        case BAR_CODE_TYPES_FAILED:
            return state.merge({
                loading: false,
                loaded: true,
                error: action.error,
            });
        case BAR_CODE_TYPES_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case BAR_CODE_TYPES_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(barcodeType => {
                    return [barcodeType.barcodeTypeId, new BarcodeType(barcodeType)];
                })),
             });
        default:
            return state;
    }
}
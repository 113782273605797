import { TableRowState } from "Components/RSTable";

export enum SearchStatus {
    Initializing,
    Loading,
    Loaded,
    LoadingFailed,
    Updating
}

export interface SearchState {
    ShowAdvancedFilters: boolean
    MenuAnchorElement: Element | null
    Status: SearchStatus
    UpdateRequestDate: Date | null
    TableRowState: { [id: string]: TableRowState; }
    TopCheckBoxIsChecked: boolean
    ShowMoreFilters: boolean;
};

export enum SearchActionType {
    CheckRow,
    ToggleAdvancedFilters,
    SetMenuAnchorElement,
    UpdateGrid,
    CheckAllRows,
    ToggleMoreFilters
}

export interface BaseSearchAction {
};

export interface SearchAction {
    SearchType?: SearchActionType
    RowKey?: string | null
    UpdateRequestDate?: Date | null
    MenuAnchorElement?: Element | null
};

export abstract class SearchActions {
    public abstract ToggleAdvancedFilters(): SearchAction;
    public abstract SetMenuAnchorElement(element: Element | null): SearchAction;
    public abstract RefreshGrid(): SearchAction;
    public abstract CheckRow(key: string): SearchAction;
    public abstract CheckAllRows(): SearchAction;
    public abstract ToggleMoreFilters(): SearchAction;
}
import React, { useEffect } from 'react';

import BarcodeListing from 'Components/BarCodes/BarcodeListing';
import VariantListing from 'Components/Variants/VariantListing';
import EditProduct from 'Components/Product/EditProduct';
import { fetchProduct } from 'data/Product/actions/fetchProduct';
import { getProductById } from 'data/Product/selectors/getProductState';
import SharedBreadcrumbs from 'shared/SharedBreadcrumbs';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    AppBar,
    Tabs,
    Tab,
    Box,
    makeStyles,
    Theme,
} from '@material-ui/core';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#EDF7FF',
    },
    breadcrumbs: {
        padding: '10px 0',
    },
    indicator: {
        backgroundColor: 'white',
    },
}));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={ value !== index }
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { value === index && (
          <Box p={ 3 }>
            {children}
          </Box>
        )}
      </div>
    );
 }

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const EditExistingProduct = () => {
    const { ID } = useParams();
    const productId = parseInt(ID);

    const dispatch = useDispatch();
    const product = useSelector(state => getProductById(productId)(state));

    useEffect(() => {
        dispatch(fetchProduct({ "productId": productId }));
    }, [dispatch]);

    const classes = useStyles();
    const muiGlobal = globalMui();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            { !!productId && product.size > 0 &&
                <SharedBreadcrumbs
                    display={ !!productId }
                    links={{ 1: { link: '/Products', name: 'Product' }}}
                    last={ product.first().title }
                />}
            <div className={ classes.root }>
                <AppBar
                    position="sticky"
                    color="transparent"
                    className={ muiGlobal.appBar }
                >
                    <Tabs
                        indicatorColor="primary"
                        value={ value }
                        onChange={ handleChange }
                        aria-label="Existing Product Detail View"
                    >
                        <Tab label="DETAILS" {...a11yProps(0)} />
                        <Tab label="VARIANTS" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={ value } index={ 0 }>
                    <EditProduct ID={ productId } />
                    <BarcodeListing productId={ productId } />
                </TabPanel>
                <TabPanel value={ value } index={ 1 }>
                    <VariantListing productId={ productId } />
                </TabPanel>
            </div>
        </div>
    );
}

export default EditExistingProduct;
import request from 'shared/request';
import { binRequested, binSuccess, binFailed } from 'data/Bin/actions/actions';

export function fetchBin(parameters = {}) {
    return dispatch => {
        dispatch(binRequested());
        request.get('bins', { params: { ...parameters }})
        .then(data => {
            dispatch(binSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(binFailed(error));
            return error;
        });
    }
}
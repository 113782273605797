import Products from "@readystock-javascript/common-library/lib/Models/RestAPI/Products";
import { EditAction, EditActions, EditState, EditActionType } from './EditActions';
import { SearchState, SearchStatus, SearchActionType, SearchAction } from "./SearchActions";
import Variations from "@readystock-javascript/common-library/lib/Models/RestAPI/Variations";
import { Product } from "@readystock-javascript/common-library/lib/Models/RestAPI/ProductCreate";
import ProductType from "@readystock-javascript/common-library/lib/Models/RestAPI/ProductType";
import ProductAndVariations from "@readystock-javascript/common-library/lib/Models/ExtensionModels/ProductAndVariations";
import { ProductsAction } from "./ProductsActions2";
import { exception } from "console";
import { restClient } from 'Utils/ApiClient';

export interface ProductState extends EditState {
    productAndVariations: ProductAndVariations | null
    newProductAndVariations: ProductAndVariations | null
    showVariations: boolean
    variationsTable: SearchState

    productTypes?: ProductType[]
}

export const DefaultProductState: ProductState = {
    productAndVariations: null,
    newProductAndVariations: null,
    SavedID: null,
    SavedAt: null,
    showVariations: true,
    variationsTable: {
        Status: SearchStatus.Initializing,
        MenuAnchorElement: null,
        ShowAdvancedFilters: false,
        UpdateRequestDate: null,
        TableRowState: {},
        TopCheckBoxIsChecked: false,
        ShowMoreFilters: false
    }
};

export enum ProductActionType {
    LoadProduct = "ProductActionType_LoadBin",
    UpdateField = "ProductActionType_UpdateField",
    Saved = "ProductActionType_Saved",
    ClearMessage = "ProductActionType_ClearMessage",
    AddBarcode = "ProductActionType_AddBarcode",
    ToggleVariations = "ProductActionType_ToggleVariations",
    CheckRow = 'ProductActionType_CheckRow',
    CheckAllRows = 'ProductActionType_CheckAllRows',
    NewVariationUpdateField = "ProductActionType_NewVariationUpdateField",
    LoadProductTypes = "ProductActionType_LoadProductTypes",
    LoadVariations = "ProductActionType_LoadVariations",
    SetErrors = "ProductActionType_SetErrors"
}

export interface ProductAction extends EditAction {
    type: ProductActionType;
    product?: Products;
    searchAction?: SearchAction;
    productTypes?: ProductType[];
    variations?: Variations[];
}

export class NewVariationActions implements EditActions {
    public SetErrors(errors: string[]): EditAction {
        throw new Error("Method not implemented.");
    }
    public UpdateRecordField(name: string, value: any): EditAction {
        let result: ProductAction = {
            type: ProductActionType.NewVariationUpdateField,
            Name: name,
            Value: value
        };
        return result;
    }
    public Save(): Promise<EditAction> {
        throw new Error("Method not implemented.");
    }
    public SaveAndNew(): EditAction {
        throw new Error("Method not implemented.");
    }
    public Delete(): void {
        throw new Error("Method not implemented.");
    }
    public New(): EditAction {
        throw new Error("Method not implemented.");
    }
    public ClearMessage(): EditAction {
        throw new Error("Method not implemented.");
    }

}

export class ProductActions implements EditActions {
    public ClearMessage(): EditAction {
        let result: ProductAction = {
            type: ProductActionType.ClearMessage,
            EditActionType: EditActionType.ClearMessage
        };
        return result;
    }
    private _New(): Products {
        return new Products({});
    }
    public Save(record: any): Promise<ProductAction> {
        let productAndVariations = record as ProductAndVariations;

        let productResult: Promise<Products>;

        let missingFields = productAndVariations.MissingFields();
        if (missingFields.length > 0) {
            return Promise.resolve(this.SetErrors(missingFields.map(missingField => missingField + ' is required')) as ProductAction);
        }

        let unknownErrorResult = Promise.resolve(this.SetErrors(["An unknown error occurred"]) as ProductAction);

        if (productAndVariations.Product === null || productAndVariations.Variations === null) {
            return unknownErrorResult;
        }

        if (productAndVariations.Product.productId === undefined || productAndVariations.Product.productId === 0) {
            productResult = restClient.Products.CreateProduct(productAndVariations.Product);
        }
        else {
            productResult = restClient.Products.UpdateProduct(productAndVariations.Product);
        }

        return productResult.then(x => {
            let variationsResult: Promise<Variations[]>;

            if (productAndVariations.Variations === null) {
                return unknownErrorResult;
            }

            if (productAndVariations.Variations[0].variationId === undefined || productAndVariations.Variations[0].variationId === 0) {
                productAndVariations.Variations[0].productId = x.productId;
                variationsResult = restClient.Variations.CreateVariations(productAndVariations.Variations);
            } else {
                variationsResult = restClient.Variations.UpdateVariations(productAndVariations.Variations);
            }

            return variationsResult.then(y => {
                let action: ProductAction = {
                    type: ProductActionType.Saved,
                    SavedID: String(x.productId),
                    EditActionType: EditActionType.Saved
                };
                return action;
            }).catch((x: Error) => {
                return this.SetErrors([x.message]) as ProductAction;
            });
        }).catch((x: Error) => {
            return this.SetErrors([x.message]) as ProductAction;
        });
    }
    public SaveAndNew(product: any): EditAction {
        let result: ProductAction = this.LoadProduct(this._New());
        result.SavedID = "1";
        result.EditActionType = EditActionType.Saved;
        return result;
    }
    public Delete(): void {
        throw new Error("Method not implemented.");
    }
    public New(): EditAction {
        let result: ProductAction = this.LoadProduct(this._New());
        return result;
    }
    public UpdateRecordField(name: string, value: any): EditAction {
        let result: ProductAction = {
            type: ProductActionType.UpdateField,
            Name: name,
            Value: value
        };
        return result;
    }
    public LoadProduct(product: Products): ProductAction {
        return {
            type: ProductActionType.LoadProduct,
            product: product
        };
    }
    public LoadVariations(variations: Variations[]): ProductAction {
        return {
            type: ProductActionType.LoadVariations,
            variations: variations
        };
    }
    public AddBarcode(): ProductAction {
        return {
            type: ProductActionType.AddBarcode
        };
    }
    public ToggleVariations(): ProductAction {
        return {
            type: ProductActionType.ToggleVariations
        };
    }
    public CheckAllRows(): ProductAction {
        let result: ProductAction = {
            type: ProductActionType.CheckAllRows,
            searchAction: {
                SearchType: SearchActionType.CheckAllRows
            }
        };
        return result;
    }
    public CheckRow(key: string): ProductAction {
        let result: ProductAction = {
            type: ProductActionType.CheckRow,
            searchAction: {
                SearchType: SearchActionType.CheckRow,
                RowKey: key
            }
        };
        return result;
    }
    public LoadProductTypes(productTypes: ProductType[]): ProductAction {
        return {
            type: ProductActionType.LoadProductTypes,
            productTypes: productTypes
        };
    }
    public SetErrors(errors: string[]): EditAction {
        let result: ProductAction = {
            type: ProductActionType.SetErrors,
            EditActionType: EditActionType.SetErrors,
            Errors: errors
        };
        return result;
    }
}
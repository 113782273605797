import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWarehouseById } from 'data/Warehouse/selectors/getWarehouseState';
import { saveWarehouse, updateWarehouse } from 'data/Warehouse/actions/saveWarehouse';
import { fetchWarehouse } from 'data/Warehouse/actions/fetchWarehouse';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';
import SharedBreadcrumbs from 'shared/SharedBreadcrumbs';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

interface warehouseEditValues {
    warehouseId: number | null,
    name: string,
    description: string,
}

interface barcodeTypes {
    barcodeTypeId: number,
    name: string,
    description: string,
}

const WarehouseEdit = () => {
    const { warehouseId } = useParams();
    const muiGlobal = globalMui();
    const history = useHistory();

    const warehouse = useSelector(state => getWarehouseById( parseInt(warehouseId) )(state));
    const wId = parseInt(warehouseId);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWarehouse(wId));
    }, [dispatch]);

    let initialFormValues: warehouseEditValues = {
        warehouseId: null,
        name: '',
        description: '',
    }

    if(warehouse.size > 0) {
        const displayWarehouse = warehouse.get(wId);

        initialFormValues = {
            warehouseId: displayWarehouse.warehouseId,
            name: displayWarehouse.name,
            description: displayWarehouse.description,
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialFormValues
        },
        onSubmit: (values: warehouseEditValues) => {
            if (!wId) {
                const data = new Array({
                    name: values.name,
                    description: values.description,
                });

                //@ts-ignore
                dispatch(saveWarehouse(data)).then(warehouseData => {
                    if (warehouseData) {
                        const newWarehouseId = warehouseData.data[0].warehouseId;
                        if (newWarehouseId) {
                            dispatch(appMessageSuccess([{ message: 'Warehouse added.', level: 'success' }]));
                            history.push(`/warehouse/${newWarehouseId}`)
                        }
                    }
                });
            }

            if (wId) {
                const data = new Array({
                    warehouseId: values.warehouseId,
                    name: values.name,
                    description: values.description,
                });

                dispatch(updateWarehouse(data));
            }
        },
    });

    const clearForm = () => {
        formik.resetForm();
    }

    return (
        <div>
            { !!wId && warehouse.size > 0 &&
                <SharedBreadcrumbs
                    display={ !!wId }
                    links={{ 1: { link: '/warehouses', name: 'Warehouse' }}}
                    last={ warehouse.first().name }
                />}
            <h1>{ !wId && <span>Add Warehouse</span> }</h1>
            <div className={ muiGlobal.paperMorePadding }>
                <div>
                    <form onSubmit={ formik.handleSubmit }>
                        <Grid container spacing={ 2 }>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="Name"
                                    name="name"
                                    label="name"
                                    value={ formik.values.name }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={ formik.values.description }
                                    onChange={ formik.handleChange }
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={ 4 }
                                    fullWidth
                                />
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} item></Grid>
                            <Grid item >
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={ clearForm }
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default WarehouseEdit;
import { WAREHOUSE_REQUEST, WAREHOUSE_SUCCESS, WAREHOUSE_FAILED } from 'shared/state/action-type';

export function warehouseFailed(error) {
    return {
        type: WAREHOUSE_FAILED,
        payload: error,
    }
}

export function warehouseRequested() {
    return {
        type: WAREHOUSE_REQUEST,
    };
}

export function warehouseSuccess(data) {
    return {
        type: WAREHOUSE_SUCCESS,
        payload: data,
    };
}
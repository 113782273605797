import { BAR_CODE_REQUEST, BAR_CODE_SUCCESS, BAR_CODE_FAILED } from 'shared/state/action-type';

export function barcodeFailed(error) {
    return {
        type: BAR_CODE_FAILED,
        payload: error,
    }
}

export function barcodeRequested() {
    return {
        type: BAR_CODE_REQUEST,
    };
}

export function barcodeSuccess(barcode) {
    return {
        type: BAR_CODE_SUCCESS,
        payload: barcode,
    };
}
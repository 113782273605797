import request from 'shared/request';
import { productRequested, productSuccess, productFailed } from 'data/Product/actions/actions';

export function fetchProduct(parameters = {}) {
    return dispatch => {
        dispatch(productRequested());
        request.get('products', { params: { ...parameters }})
        .then(data => {
            dispatch(productSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(productFailed(error));
            return error;
        });
    }
}
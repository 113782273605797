import request from 'shared/request';
import { binRequested, binSuccess, binFailed } from 'data/Bin/actions/actions';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';


export function saveBin(parameters = {}) {
    return dispatch => {
        dispatch(binRequested());
        return request.post('bins', parameters)
        .then(data => {
            dispatch(binSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(binFailed(error.message.response.data.error));
            dispatch(appMessageSuccess([{ message: error.message.response.data.error, level: 'error' }]));
            // return error;
        });
    }
}

export function updateBin(parameters = {}) {
    return dispatch => {
        dispatch(binRequested());
        return request.put('bins', parameters)
        .then(data => {
            dispatch(binSuccess(data));
            dispatch(appMessageSuccess([{ message: 'Bin update.', level: 'success' }]));
            return data;
        })
        .catch(error => {
            dispatch(binFailed(error));
            dispatch(appMessageSuccess([{ message: error, level: 'error' }]));
            return error;
        });
    }
}
import React, { Fragment } from 'react';
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Routes from 'Components/Routes'
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Menu,
    MenuItem
} from 'Components/Menu';
import TopMenu from './TopMenu';

import ReadyStockLogo from 'Assets/SideBySide.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        icon: {
            width: "200px"
        },
        iconDiv: {
            textAlign: "center",
            backgroundColor: "#24303c",
            paddingTop: "1em"
        },
        header: {
            backgroundColor: "#24303c",
            zIndex: 1300
        },
        rightHandTools: {
            display: "block",
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: 1200
        }
    }),
);

interface ResponsiveDrawerProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container?: Element;
}

export default function Layout(props: ResponsiveDrawerProps) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Hidden xsDown>
                <div className={`${classes.toolbar} ${classes.iconDiv}`} style={{ height: "64px" }}>
                    {/*<img src={ReadyStockLogo} className={classes.icon} alt="ReadyStock" />*/}
                </div>
            </Hidden>
            <Divider />
            <List>
                {Menu().map((menuItem: MenuItem, index) => (
                    <ListItem
                        button
                        key={menuItem.Label}
                        component={RouterLink}
                        to={menuItem.Url}
                        selected={ menuItem.Url === location.pathname }
                    >
                        <ListItemText primary={menuItem.Label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={`${classes.header}`}>
                <Toolbar>
                    <div className={`${classes.toolbar} ${classes.iconDiv}`} style={{ height: "64px" }}>
                        <img src={ReadyStockLogo} className={classes.icon} alt="ReadyStock" />
                    </div>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <TopMenu />
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Routes></Routes>
            </main>
        </div>
    );
}

export function Prop(obj, key) {
    return obj[key];
}

export function GetProperties(obj) {
    return Object.getOwnPropertyNames(obj);
}

export function ExtendedProp(obj, key) {
    if (obj === null || obj === undefined)
        return null;

    if (key.includes('.')) {
        var pieces = key.split('.');
        let currentKey = pieces.shift();
        return ExtendedProp(obj[currentKey], pieces.join('.'));
    }

    return obj[key];
}

export function SetExtendedProp(obj, key, value) {
    if (obj === null || obj === undefined)
        return;

    if (key.includes('.')) {
        var pieces = key.split('.');
        let currentKey = pieces.shift();
        return SetExtendedProp(obj[currentKey], pieces.join('.'), value);
    }

    obj[key] = value;
}

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export function getParams(url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

export function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return '';
}
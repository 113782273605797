import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProduct } from 'data/Product/actions/fetchProduct';
import { getProductState } from 'data/Product/selectors/getProductState';
import { fetchProductTypes } from 'data/ProductType/actions/fetchProductTypes';
import { getProductTypeState } from 'data/ProductType/selectors/index';
import ResultsPerPage from 'shared/ResultsPerPage';
import globalMui from 'shared/globalMaterialUiClasses';

import { useHistory } from 'react-router-dom';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    Button,
    Icon,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quantity: {
            textAlign: 'center',
        },
        quantityList: {
            paddingLeft: '35px!important' as any,
            textAlign: 'center',
        },
        maxHeight: {
            maxHeight: '75vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: '0 -9px',
        },
        header: {
            alignItems: 'center',
            padding: "0 30px",
        },
        grow: {
            flexGrow: 1,
        },
        end: {
            justifySelf: 'flex-end',
        },
        arrowParent: {
            position: 'relative',
            textAlign: 'center',
        },
        arrow: {
            position: "absolute",
            right: '5px',
            top: '35%',
            color: '#999999',
            fontSize: '13px',
        },
        listingTitle: {
            borderBottom: '1px solid #E0E0E0',
            fontWeight: 'bold',
            marginBottom: '3px',
            padding: '0 11px 0 0',
        },
        relative: {
            position: "relative",
        },
        results: {
            position: "absolute",
            right: '10px',
            top: '-68px',
            zIndex: 1100,
        },
        actions: {
            padding: '0 0 20px',
        },
    }),
);

interface ProductListing {
    productId: number,
    title: string,
    description: string,
}

interface changeResult {
    value: number,
    label: string,
}

interface productTypes {
    productTypeId: number,
    name: string,
    description: string,
}

const ProductListing = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const muiGlobal = globalMui();

    const [pageSize, setPageSize] = React.useState(50);
    const [search, setSearch] = React.useState('');
    const [productTypeSearch, setProductTypeSearch] = React.useState({ label: '', value: 0 });

    const productList = useSelector(state => getProductState(state));
    const productTypes = useSelector(state => getProductTypeState(state));

    useEffect(() => {
        dispatch(fetchProduct());
        dispatch(fetchProductTypes());
    }, [dispatch]);

    const editExisting = (key: number) => () => {
        history.push(`product/${key}`);
    }

    const changePageSize = (selection: changeResult) => {
        setPageSize(selection.value);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handleProductType = (selection: changeResult) => {
        setProductTypeSearch(selection);
    }

    function productTypesDropdown() {

        if (productTypes.size === 0) {
            return null;
        }

        const productTypeOptions = productTypes.map((product: productTypes) => {
            return {
                label: product.name,
                value: product.productTypeId
            };
        }).toList().toArray() ?? [];

        return (
            <Autocomplete
                id="product-type-id"
                options={ productTypeOptions as changeResult[] }
                getOptionLabel={ option => option.label }
                onChange={ (event, item) => {
                    if (item) {
                        handleProductType(item);
                    }
                }}
                value={{ label: productTypeSearch.label, value: productTypeSearch.value }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Product Type"
                            variant="outlined"
                            name="product_types"
                            size="small"
                        />);
                    }
                }
            />
        );
    }

    const buildList = () => {
        return productList.map((product: ProductListing, key: number) => {

            return (
                <Grid
                    container
                    spacing={ 2 }
                    key={ key }
                    className={ muiGlobal.listing }
                    onClick={ editExisting(key) }
                >
                    <Grid xs={ 2 } item>
                        sku
                    </Grid>
                    <Grid xs={ 3 } item>
                        { product.title }
                    </Grid>
                    <Grid xs={ 3 } item>
                        { product.description }
                    </Grid>
                    <Grid xs={ 2 } item>
                        type
                    </Grid>
                    <Grid xs={ 1 } item>
                        unknown
                    </Grid>
                    <Grid xs={ 1 } item className={ classes.arrowParent }>
                        0
                        <ArrowForwardIosIcon className={ classes.arrow } />
                    </Grid>
                </Grid>
            );
        }).valueSeq();
    }

    return (
        <div className={ classes.relative } >
            <div className={ classes.results }>
                <Grid className={ classes.end } xs={ 12 } item>
                    <ResultsPerPage
                        updateResults={ changePageSize }
                        resultsValue={ pageSize }
                    />
                </Grid>
            </div>
            <div className={ muiGlobal.paperNoPadNoShadow }>
                <Grid container spacing={ 2 } className={ classes.actions }>
                    <Grid xs={ 5 } item>
                        <TextField
                            id="search"
                            name="search"
                            label="Search Title/Sku"
                            value={ search }
                            onChange={ handleSearch }
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={ 3 } item>
                        { productTypesDropdown() }
                    </Grid>
                </Grid>
                <Grid container spacing={ 2 } className={ classes.listingTitle }>
                    <Grid xs={ 2 } item>
                        SKU
                    </Grid>
                    <Grid xs={ 3 } item>
                        Title
                    </Grid>
                    <Grid xs={ 3 } item>
                        Description
                    </Grid>
                    <Grid xs={ 2 } item>
                        Product Type
                    </Grid>
                    <Grid xs={ 1 } item>
                        Variants
                    </Grid>
                    <Grid xs={ 1 } item>
                        Avaiable
                    </Grid>
                </Grid>
                <div className={ classes.maxHeight }>
                    { buildList() }
                </div>
            </div>
        </div>
    );
}

export default ProductListing;
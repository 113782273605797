import { BIN_REQUEST, BIN_SUCCESS, BIN_FAILED } from 'shared/state/action-type';

export function binFailed(error) {
    return {
        type: BIN_FAILED,
        payload: error,
    }
}

export function binRequested() {
    return {
        type: BIN_REQUEST,
    };
}

export function binSuccess(data) {
    return {
        type: BIN_SUCCESS,
        payload: data,
    };
}
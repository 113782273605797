import { Record } from 'immutable';

export class Barcode extends Record({
    barcode: '',
    barcodeId: null,
    barcodeTypeID: null,
    createdBy: null,
    dateCreated: '',
    dateUpdated: '',
    productId: null,
    saving: false,
    updatedBy: null,
    variationId: null,
    vendorId: null,
    vendorVariationId: null,
}) {
}

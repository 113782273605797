import { PRODUCT_REQUEST, PRODUCT_SUCCESS, PRODUCT_FAILED } from 'shared/state/action-type';

export function productFailed(error) {
    return {
        type: PRODUCT_FAILED,
        payload: error,
    }
}

export function productRequested() {
    return {
        type: PRODUCT_REQUEST,
    };
}

export function productSuccess(product) {
    return {
        type: PRODUCT_SUCCESS,
        payload: product,
    };
}
import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';
import { SetAccountMenuElement } from 'Actions/Layout';
import { useCookies } from 'react-cookie';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Config from 'Config'

export default function TopMenu() {

    let dispatch = useDispatch();
    let accountMenuElement: Element | null = useSelector(state => state.LayoutState.AccountMenuElement);

    const accountMenuHandleClose = () => {
        dispatch(SetAccountMenuElement(null));
    };

    const accountMenuHandleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        dispatch(SetAccountMenuElement(e.currentTarget));
    };

    let [, setCookies] = useCookies([Config.REACT_APP_AccountIdCookieKey ?? '', Config.REACT_APP_UserIdCookieKey ?? '']);

    const handleLogout = () => {
        setCookies(Config.REACT_APP_RTokenCookieKey ?? '', '', {
            path: '/',
            domain: Config.REACT_APP_CookieDomain
        });
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            rightHandTools: {
                display: "block",
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: 1400
            }
        }),
    );

    let classes = useStyles();

    return (
        <div className={classes.rightHandTools}>
            <IconButton style={{ color: "white" }}>
                <NotificationsIcon />
            </IconButton>
            <IconButton onClick={accountMenuHandleClick} aria-controls="AccountMenu" style={{ color: "white" }}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="AccountMenu"
                anchorEl={accountMenuElement}
                keepMounted
                open={Boolean(accountMenuElement)}
                onClose={accountMenuHandleClose}
                style={{ zIndex: 1500 }}
            >
                <MenuItem onClick={accountMenuHandleClose}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    );
}
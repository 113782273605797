import {
    SearchState,
    SearchActions,
    SearchAction,
    SearchStatus,
    SearchActionType
} from './SearchActions';
import Vendors from '@readystock-javascript/common-library/lib/Models/RestAPI/Vendors';

export enum VendorsActionType {
    Initializing = 'VendorsActionType_Initializing',
    Loading = 'VendorsActionType_Loading',
    Loaded = 'VendorsActionType_Loaded',
    LoadingFailed = 'VendorsActionType_LoadingFailed',
    ToggleAdvancedFilters = 'VendorsActionType_ToggleAdvancedFilters',
    SetMenuAnchorElement = 'VendorsActionType_SetMenuAnchorElement',
    UpdateGrid = 'VendorsActionType_RefreshGrid',
    CheckRow = "VendorsActionType_CheckRow",
    CheckAllRows = "VendorsActionType_CheckAllRows",
    ToggleMoreFilters = "VendorsActionType_ToggleMoreFilters"
}

export interface VendorsState extends SearchState {
    Vendors: Vendors[]
}

export const DefaultVendorsState: VendorsState = {
    Status: SearchStatus.Initializing,
    MenuAnchorElement: null,
    ShowAdvancedFilters: false,
    Vendors: [],
    UpdateRequestDate: null,
    TableRowState: {},
    TopCheckBoxIsChecked: false,
    ShowMoreFilters: false
};

export interface VendorsAction extends SearchAction {
    type: VendorsActionType
    Vendors?: Vendors[];
}

export class VendorsActions extends SearchActions {
    public ToggleMoreFilters(): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.ToggleAdvancedFilters,
            SearchType: SearchActionType.ToggleMoreFilters
        };
        return result;
    }
    public RefreshGrid(): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.UpdateGrid,
            SearchType: SearchActionType.UpdateGrid,
            UpdateRequestDate: new Date(Date.now())
        };
        return result;
    }
    public CheckAllRows(): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.CheckAllRows,
            SearchType: SearchActionType.CheckAllRows
        };
        return result;
    }
    public ToggleAdvancedFilters(): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.ToggleAdvancedFilters,
            SearchType: SearchActionType.ToggleAdvancedFilters
        };
        return result;
    }
    public SetMenuAnchorElement(element: Element | null): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.SetMenuAnchorElement,
            SearchType: SearchActionType.SetMenuAnchorElement,
            MenuAnchorElement: element
        };
        return result;
    }
    public LoadVendors(Vendors: Vendors[]): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.Loaded,
            Vendors: Vendors
        };
        return result;
    }
    public CheckRow(key: string): SearchAction {
        let result: VendorsAction = {
            type: VendorsActionType.CheckRow,
            SearchType: SearchActionType.CheckRow,
            RowKey: key
        };
        return result;
    }
}
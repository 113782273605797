import {
    SearchState,
    SearchActions,
    SearchStatus,
    SearchAction,
    SearchActionType
} from './SearchActions';
import Sales, { SalesExtended } from '@readystock-javascript/common-library/lib/Models/RestAPI/Sales';

export enum SalesActionType {
    Initializing = 'SalesActionType_Initializing',
    Loading = 'SalesActionType_Loading',
    Loaded = 'SalesActionType_Loaded',
    LoadingFailed = 'SalesActionType_LoadingFailed',
    ToggleAdvancedFilters = 'SalesActionType_ToggleAdvancedFilters',
    SetMenuAnchorElement = 'SalesActionType_SetMenuAnchorElement',
    UpdateGrid = 'SalesActionType_RefreshGrid',
    CheckRow = "SalesActionType_CheckRow",
    CheckAllRows = "SalesActionType_CheckAllRows"
}

export interface SalesState extends SearchState {
    Sales: SalesExtended[]
}

export const DefaultSalesState: SalesState = {
    Status: SearchStatus.Initializing,
    MenuAnchorElement: null,
    ShowAdvancedFilters: false,
    Sales: [],
    UpdateRequestDate: null,
    TableRowState: {},
    TopCheckBoxIsChecked: false,
    ShowMoreFilters: false
};

export interface SalesAction extends SearchAction {
    type: SalesActionType;
    Sales?: SalesExtended[];
}

export class SalesActions extends SearchActions {
    public ToggleMoreFilters(): SearchAction {
        throw new Error("Method not implemented.");
    }
    public CheckAllRows(): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.CheckAllRows,
            SearchType: SearchActionType.CheckAllRows
        };
        return result;
    }
    public CheckRow(key: string): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.CheckRow,
            SearchType: SearchActionType.CheckRow,
            RowKey: key
        };
        return result;
    }
    public RefreshGrid(): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.UpdateGrid,
            SearchType: SearchActionType.UpdateGrid,
            UpdateRequestDate: new Date(Date.now())
        }
        return result;
    }
    public ToggleAdvancedFilters(): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.ToggleAdvancedFilters,
            SearchType: SearchActionType.ToggleAdvancedFilters
        };
        return result;
    }
    public SetMenuAnchorElement(element: Element | null): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.SetMenuAnchorElement,
            SearchType: SearchActionType.SetMenuAnchorElement,
            MenuAnchorElement: element
        };
        return result;
    }
    public LoadSales(sales: SalesExtended[]): SearchAction {
        let result: SalesAction = {
            type: SalesActionType.Loaded,
            Sales: sales
        };
        return result;
    }
}
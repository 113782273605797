
import request from 'shared/request';
import { BAR_CODE_TYPES_REQUEST, BAR_CODE_TYPES_SUCCESS, BAR_CODE_TYPES_FAILED } from 'shared/state/action-type';

export function barcodeTypesFailed(error) {
    return {
        type: BAR_CODE_TYPES_FAILED,
        payload: error,
    }
}

function barcodeTypesRequested() {
    return {
        type: BAR_CODE_TYPES_REQUEST,
    };
}

function barcodeTypesSuccess(barcodeTypes) {
    return {
        type: BAR_CODE_TYPES_SUCCESS,
        payload: barcodeTypes,
    };
}

export function fetchBarcodeTypes(parameters = {}) {
    return dispatch => {
        dispatch(barcodeTypesRequested());
        return request.get('barcode_types', { params: { ...parameters }})
        .then(data => {
            dispatch(barcodeTypesSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(barcodeTypesFailed(error));
            return error;
        });
    }
}
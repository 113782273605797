import {
    ProductsState,
    ProductsAction,
    ProductsActionType,
    DefaultProductsState
} from "Actions/ProductsActions2";
import { SearchStatus } from "Actions/SearchActions";
import SearchReducer from './SearchReducer';
import { TableRowState } from "Components/RSTable";

const ProductsReducerRemove = function (state: ProductsState = DefaultProductsState, action: ProductsAction): ProductsState {
    let searchState = SearchReducer(state, action);

    let result: ProductsState;
    switch (action.type) {
        case ProductsActionType.Initializing:
            result = {
                ...state,
                Status: SearchStatus.Initializing,
                Products: []
            };
            break;
        case ProductsActionType.Loaded:
            result = {
                ...state,
                Status: SearchStatus.Loaded,
                Products: action.Products ?? [],
                TableRowState: TableRowState.DefaultFromList(action.Products ?? [], 'productId')
            };
            break;
        case ProductsActionType.SetTab:
            result = {
                ...state,
                TabIndex: action.TabIndex ?? 0
            };
            break;
        case ProductsActionType.MoreFilters:
            result = {
                ...state,
                ShowMoreFilters: !state.ShowMoreFilters
            };
            break;
        case ProductsActionType.ToggleAdvancedFilters:
        case ProductsActionType.SetMenuAnchorElement:
        case ProductsActionType.UpdateGrid:
        case ProductsActionType.CheckRow:
        case ProductsActionType.CheckAllRows:
            result = {
                ...state,
                ...searchState
            }
            break;
        default:
            return state;
    }

    return result;
};

export default ProductsReducerRemove;
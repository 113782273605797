import {
    SearchState,
    SearchActions,
    SearchAction,
    SearchStatus,
    SearchActionType
} from './SearchActions';
import Inventory from '@readystock-javascript/common-library/lib/Models/RestAPI/Inventory';

export enum InventoriesActionType {
    Initializing = 'InventoriesActionType_Initializing',
    Loading = 'InventoriesActionType_Loading',
    Loaded = 'InventoriesActionType_Loaded',
    LoadingFailed = 'InventoriesActionType_LoadingFailed',
    ToggleAdvancedFilters = 'InventoriesActionType_ToggleAdvancedFilters',
    SetMenuAnchorElement = 'InventoriesActionType_SetMenuAnchorElement',
    UpdateGrid = 'InventoriesActionType_RefreshGrid',
    CheckRow = "InventoriesActionType_CheckRow",
    CheckAllRows = "InventoriesActionType_CheckAllRows",
    ToggleMoreFilters = "InventoriesActionType_ToggleMoreFilters"
}

export interface InventoriesState extends SearchState {
    Inventory: Inventory[]
}

export const DefaultInventoriesState: InventoriesState = {
    Status: SearchStatus.Initializing,
    MenuAnchorElement: null,
    ShowAdvancedFilters: false,
    Inventory: [],
    UpdateRequestDate: null,
    TableRowState: {},
    TopCheckBoxIsChecked: false,
    ShowMoreFilters: false
};

export interface InventoriesAction extends SearchAction {
    type: InventoriesActionType
    Inventory?: Inventory[];
}

export class InventoriesActions extends SearchActions {
    public ToggleMoreFilters(): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.ToggleMoreFilters,
            SearchType: SearchActionType.ToggleMoreFilters
        };
        return result;
    }
    public RefreshGrid(): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.UpdateGrid,
            SearchType: SearchActionType.UpdateGrid,
            UpdateRequestDate: new Date(Date.now())
        };
        return result;
    }
    public CheckAllRows(): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.CheckAllRows,
            SearchType: SearchActionType.CheckAllRows
        };
        return result;
    }
    public ToggleAdvancedFilters(): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.ToggleAdvancedFilters,
            SearchType: SearchActionType.ToggleAdvancedFilters
        };
        return result;
    }
    public SetMenuAnchorElement(element: Element | null): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.SetMenuAnchorElement,
            MenuAnchorElement: element,
            SearchType: SearchActionType.SetMenuAnchorElement
        };
        return result;
    }
    public LoadInventory(Inventory: Inventory[]): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.Loaded,
            Inventory: Inventory
        };
        return result;
    }
    public CheckRow(key: string): SearchAction {
        let result: InventoriesAction = {
            type: InventoriesActionType.CheckRow,
            RowKey: key,
            SearchType: SearchActionType.CheckRow
        };
        return result;
    }
}
import {
    InventoriesState,
    InventoriesAction,
    InventoriesActionType,
    DefaultInventoriesState
} from "Actions/InventoriesActions";
import { SearchStatus } from "Actions/SearchActions";
import SearchReducer from './SearchReducer';
import { TableRowState } from "Components/RSTable";

const InventoriesReducer = function (state: InventoriesState = DefaultInventoriesState, action: InventoriesAction): InventoriesState {
    let searchState = SearchReducer(state, action);

    let result: InventoriesState;
    switch (action.type) {
        case InventoriesActionType.Initializing:
            result = {
                ...state,
                Status: SearchStatus.Initializing,
                Inventory: []
            };
            break;
        case InventoriesActionType.Loaded:
            result = {
                ...state,
                Status: SearchStatus.Loaded,
                Inventory: action.Inventory ?? [],
                TableRowState: TableRowState.DefaultFromList(action.Inventory ?? [], 'inventoryId')
            };
            break;
        case InventoriesActionType.ToggleAdvancedFilters:
        case InventoriesActionType.SetMenuAnchorElement:
        case InventoriesActionType.UpdateGrid:
        case InventoriesActionType.CheckRow:
        case InventoriesActionType.CheckAllRows:
            result = {
                ...state,
                ...searchState
            }
            break;
        default:
            return state;
    }

    return result;
};

export default InventoriesReducer;
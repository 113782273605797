import {
    EditState,
    EditAction,
    EditActionType
} from "Actions/EditActions";

export default function EditReducer(state: EditState, action: EditAction): EditState {
    switch (action.EditActionType) {
        case EditActionType.Saved:
            return {
                ...state,
                SavedID: action.SavedID ?? "",
                SavedAt: Date.now()
            };
        case EditActionType.ClearMessage:
            return {
                ...state,
                SavedAt: null,
                SavedID: null
            };
        case EditActionType.SetErrors:
            return {
                ...state,
                Errors: action.Errors,
                SavedID: null,
                SavedAt: null
            };
        default:
            return state;
    }
}
import { BAR_CODE_REQUEST, BAR_CODE_SUCCESS, BAR_CODE_FAILED } from 'shared/state/action-type';

import { Barcode } from 'data/Barcode/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function BarcodeReducer (state = new BaseState(), action) {
    switch (action.type) {
        case BAR_CODE_FAILED:
            return state.merge({
                loading: false,
                loaded: false,
                error: action.error,
            });
        case BAR_CODE_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case BAR_CODE_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(barcode => {
                    return [barcode.barcodeId, new Barcode(barcode)];
                })),
             });
        default:
            return state;
    }
}
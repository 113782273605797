
import {
    LayoutState,
    DefaultLayoutState,
    LayoutAction,
    LayoutActionType
} from 'Actions/Layout'

const LayoutReducer = (state: LayoutState = DefaultLayoutState, action: LayoutAction) => {
    switch (action.type) {
        case LayoutActionType.SetAccountMenuElement:
            return {
                ...state,
                AccountMenuElement: action.AccountMenuElement
            };
        default:
            return state;
    }
};

export default LayoutReducer;
import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BarcodeEdit from 'Components/BarCodes/BarcodeEdit';
import { fetchBarcode } from 'data/Barcode/actions/fetchBarcode';
import { getBarcodeState } from 'data/Barcode/selectors/getBarcodeState';
import { fetchBarcodeTypes } from 'data/BarcodeType/actions/fetchBarcodeTypes';
import { getBarcodeTypeState } from 'data/BarcodeType/selectors/index';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        quantity: {
            textAlign: 'center',
        },
        quantityList: {
            paddingLeft: '35px!important' as any,
            textAlign: 'center',
        },
        maxHeight: {
            maxHeight: '80px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: '0 -9px',
        },
    }),
);

type BarcodeListingType = {
    productId?: number,
};

interface BarcodeListing {
    barcodeTypeID: number,
    productId: number,
    barcode: string,
    barcodeId: number,
}

const BarcodeListing: React.FC<BarcodeListingType> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const muiGlobal = globalMui();

    const { productId } = props;
    const barcodeList = useSelector(state => getBarcodeState(state));
    const barcodeTypes = useSelector(state => getBarcodeTypeState(state));

    useEffect(() => {
        dispatch(fetchBarcode({ "productId": productId }));
        dispatch(fetchBarcodeTypes());
    }, [dispatch]);


    const buildList = () => {
        const missingBarcode = (<Grid xs={ 12 } className={ muiGlobal.missingText } item>Barcodes have not been added.</Grid>);

        if (barcodeList.size === 0) {
            return missingBarcode;
        }

        //this should move to a selector
        const barcodeToProducts = barcodeList.filter((barcode: BarcodeListing) => barcode.productId === productId)

        if (barcodeToProducts.size === 0) {
            return missingBarcode;
        }

        return barcodeToProducts.map((barcode: BarcodeListing, key: number) => {
            const barcodeTypeName = barcodeTypes.get(barcode.barcodeTypeID);

            let bctName = '(missing)';
            if (barcodeTypeName) {
                bctName = barcodeTypeName.name;
            }

            return (
                <Grid container spacing={ 2 } key={ key } className={ muiGlobal.listing }>
                    <Grid xs={ 3 } item>
                        { bctName }
                    </Grid>
                    <Grid xs={ 7 } item>
                        { barcode.barcode }
                    </Grid>
                    <Grid xs={ 2 } className={ classes.quantityList } item>
                        0
                    </Grid>
                </Grid>
            );
        }).valueSeq();
    }

    return (
        <div className={ muiGlobal.paperListing }>
            <BarcodeEdit productId={ (productId ? productId : 0) } />
            <div className={ muiGlobal.listingGeneral }>
                <Grid container spacing={ 2 } className={ muiGlobal.listingTitle }>
                    <Grid xs={ 3 } item>
                        Type
                    </Grid>
                    <Grid xs={ 7 } item>
                        Value
                    </Grid>
                    <Grid xs={ 2 } className={ classes.quantity } item>
                        Quantity
                    </Grid>
                </Grid>
                <div className={ classes.maxHeight }>
                    { buildList() }
                </div>
            </div>
        </div>
    );
}

export default BarcodeListing;
import request from 'shared/request';
import { warehouseRequested, warehouseSuccess, warehouseFailed } from 'data/Warehouse/actions/actions';

export function fetchWarehouse(parameters = {}) {
    return dispatch => {
        dispatch(warehouseRequested());
        request.get('warehouses', { params: { ...parameters }})
        .then(data => {
            dispatch(warehouseSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(warehouseFailed(error));
            return error;
        });
    }
}
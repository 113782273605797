import { Config as RestConfigApi } from '@readystock-javascript/common-library/lib/API/RestAPI/RestAPIClient';
import Development from './Config.Development.json';
import Staging from './Config.Staging.json';
import Testing from './Config.Testing.json';

let config: {
    REACT_APP_LoginBaseUrl: string;
    REACT_APP_CookieDomain: string;
    REACT_APP_AccountIdCookieKey: string;
    REACT_APP_UserIdCookieKey: string;
    REACT_APP_RTokenCookieKey: string;
    RestConfigApi: RestConfigApi;
};

if (process.env.REACT_APP_ENVIRONMENT === 'Staging') {
    config = {
        ...Staging
    };
} else if (process.env.REACT_APP_ENVIRONMENT === "Testing") {
    config = {
        ...Testing
    };
} else {
    config = {
        ...Development
    };
}

export default config;
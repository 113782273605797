import { Record } from 'immutable';

export class Product extends Record({
    productId: null,
    productTypeId: null,
    productCategoryId: null,
    title: '',
    description: '',
    createdBy: null,
    dateCreated: '',
    updatedBy: null,
    dateUpdated: '',
}) {
}

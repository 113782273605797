import {
    ProductState,
    ProductAction,
    ProductActionType,
    DefaultProductState
} from "Actions/ProductActions2";
import { SetExtendedProp } from "Utils/CommonJS";
import EditReducer from "./EditReducer";
import Products from '@readystock-javascript/common-library/lib/Models/RestAPI/Products';
import Barcode from "@readystock-javascript/common-library/lib/Models/RestAPI/Barcode";
import SearchReducer from './SearchReducer';
import Variations from "@readystock-javascript/common-library/lib/Models/RestAPI/Variations";
import { stat } from "fs";
import ProductAndVariations from "@readystock-javascript/common-library/lib/Models/ExtensionModels/ProductAndVariations";
import { Product } from "Components/Product/Product";

export default function (state: ProductState = DefaultProductState, action: ProductAction): ProductState {
    let editStateStart = EditReducer(state, action);

    let result: ProductState;
    switch (action.type) {
        case ProductActionType.UpdateField:
            if (state.newProductAndVariations?.Product != null) {
                let newProductAndVariations = new ProductAndVariations({
                    ...state.newProductAndVariations
                });

                SetExtendedProp(newProductAndVariations, action.Name, action.Value);
                result = {
                    ...state,
                    ...editStateStart,
                    newProductAndVariations: newProductAndVariations
                };
            } else {
                result = {
                    ...state,
                    ...editStateStart,
                };
            }

            break;
        case ProductActionType.LoadProduct:
            let product = action.product ?? null;
            let newProduct = new Products();
            if (product !== null)
                newProduct = new Products({
                    ...product
                });

            result = {
                ...state,
                ...editStateStart,
                productAndVariations: new ProductAndVariations({
                    ...state.productAndVariations ?? new ProductAndVariations(),
                    Product: product ?? new Products(),
                    Variations: ((product ?? new Products()).productId === undefined || (product ?? new Products()).productId === 0 ? [new Variations()] : (state.productAndVariations?.Variations ?? []))
                }),
                newProductAndVariations: new ProductAndVariations({
                    ...state.newProductAndVariations ?? new ProductAndVariations(),
                    Product: newProduct,
                    Variations: (newProduct.productId === undefined || newProduct.productId === 0 ? [new Variations()] : (state.productAndVariations?.Variations ?? []))
                })
            };

            break;
        case ProductActionType.LoadVariations:
            let variations = action.variations ?? [];
            let newVariations: Variations[] = action.variations?.map(variation => {
                return new Variations({ ...variation });
            }) ?? [new Variations()];

            result = {
                ...state,
                ...editStateStart,
                productAndVariations: new ProductAndVariations({
                    ...state.productAndVariations ?? new ProductAndVariations(),
                    Variations: variations
                }),
                newProductAndVariations: new ProductAndVariations({
                    ...state.newProductAndVariations ?? new ProductAndVariations(),
                    Variations: newVariations
                })
            };
            break;
        /*
    case ProductActionType.AddBarcode:
        result = {
            ...state,
            ...editStateStart
        };

        if (result.newProduct !== undefined && result.newProduct !== null) {
            result.newProduct.Barcodes = result.newProduct?.Barcodes ?? [];
            result.newProduct.Barcodes[result.newProduct.Barcodes?.length] = new Barcode();
        }

        break;
    case ProductActionType.ToggleVariations:
        result = {
            ...state,
            ...editStateStart,
            showVariations: true,
            newVariation: new Variations({})
        };

        break;
        */
        case ProductActionType.CheckAllRows:
            if (state.variationsTable !== undefined
                && state.variationsTable !== null
                && action.searchAction !== undefined
                && action.searchAction !== null) {

                let searchStateStart = SearchReducer(state.variationsTable, action.searchAction);

                result = {
                    ...state,
                    ...editStateStart,
                    variationsTable: {
                        ...state.variationsTable,
                        ...searchStateStart
                    }
                };
            }
            else {
                result = {
                    ...state,
                    ...editStateStart
                };
            }
            break;
        /*
    case ProductActionType.NewVariationUpdateField:
        if (state.newVariation != null) {
            let newVariation = new Variations({
                ...state.newVariation
            });
            SetExtendedProp(newVariation, action.Name, action.Value);
            result = {
                ...state,
                ...editStateStart,
                newVariation: newVariation
            };
        } else {
            result = {
                ...state,
                ...editStateStart,
            };
        }

        break;
        */
        case ProductActionType.LoadProductTypes:
            result = {
                ...state,
                ...editStateStart,
                productTypes: action.productTypes
            };
            break;
        default:
            result = {
                ...state,
                ...editStateStart
            };
    }
    return result;
}
import { makeStyles, createStyles } from '@material-ui/core';

const globalMui = makeStyles(theme =>
    createStyles({
        appBar: {
            boxShadow: 'none',
            backgroundColor: '#EDF7FF',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '20px 40px',
            width: '100%',
        },
        paperMorePadding: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '40px',
            width: '100%',
        },
        paperNoPadNoShadow: {
            backgroundColor: theme.palette.background.paper,
            padding: '10px 18px',
            width: '100%',
        },
        noPnoM: {
            margin: '5px 0 0',
            padding: '0',
        },
        right: {
            textAlign: 'right',
        },
        alignItemsCenter: {
            alignItems: 'center',
        },
        breadcrumbs: {
            padding: '10px 0',
        },
        paperListing: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '20px 40px',
            width: '100%',
            margin: '20px 20px 20px 0',
        },
        listingGeneral: {
            paddingTop: '20px',
        },
        listingTitle: {
            borderBottom: '1px solid #E0E0E0',
            fontWeight: 'bold',
            marginBottom: '3px',
        },
        listing: {
            padding: '0 10px 0 5px',
            margin: '0',
            borderBottom: '1px solid #E0E0E0',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        missingText: {
            textAlign: 'center',
            fontSize: '18px',
            padding: '10px 0 2px',
        },
        arrowRelative: {
            position: 'relative',
        },
        arrow: {
            position: "absolute",
            right: '5px',
            top: '35%',
            color: '#999999',
            fontSize: '13px',
        },
    }),
);

export default globalMui;
import React, { useEffect } from 'react';
import { useSelector } from 'Utils/useSelector';

import { ProductActions } from 'Actions/ProductActions2';
import { restClient } from 'Utils/ApiClient';
import Variations from "@readystock-javascript/common-library/lib/Models/RestAPI/Variations";
import Products from '@readystock-javascript/common-library/lib/Models/RestAPI/Products';
import ProductAndVariations from "@readystock-javascript/common-library/lib/Models/ExtensionModels/ProductAndVariations";
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Save} from '@material-ui/icons';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

interface EditProductValues {
    productId: number;
    title: string;
    description: string;
    sku: string;
    productTypeId: number;
    productTypeTitle: string;
}

type EditProductProps = {
    ID?: number,
};

const EditProduct: React.FC<EditProductProps> = (props) => {
    const { ID } = props;
    const muiGlobal = globalMui();

    const dispatch = useDispatch();
    useEffect(() => {
        const productActionsInEffect = new ProductActions();

        if(ID) {
            restClient.Products.GetProduct(ID).then(product => {
                dispatch(productActionsInEffect.LoadProduct(product));
            }).catch((error: Error) => {

            });

            restClient.Variations.GetVariations(ID).then(product =>
                 dispatch(productActionsInEffect.LoadVariations(product))
            ).catch((error: Error) => {

            });
        }

        //Gather Data for Product Types
        restClient.ProductTypes.GetProductTypes().then(productTypes => {
            if (productTypes.length > 0) {
                dispatch(productActionsInEffect.LoadProductTypes(productTypes));
            }
        }).catch((x: Error) => {
            //setup an error message
        });
    }, [dispatch]);

    const productState = useSelector(state => state.ProductRemove);

    const productTypeOptions = productState.productTypes?.map(productType => {
        return {
            label: productType.name,
            value: productType.productTypeId
        };
    }) ?? [];

    const productActionSave = new ProductActions;

    let initialFormValues: EditProductValues = {
        productId: 0,
        title: '',
        description: '',
        sku: '',
        productTypeId: 0,
        productTypeTitle: '',
    }

    if (ID) {
        //i hate everything TS is forcing me to do here
        const existingProduct = productState.productAndVariations;
        let existingVariation = productState.productAndVariations?.Variations;

        if (existingProduct !== null && existingProduct.Product !== null) {

            let sku = '';
            if (
                existingVariation !== null
                && existingVariation !== undefined
                && existingVariation.length > 0
            ) {
                sku = existingVariation[0].sku;
            }

            //get productTypeTitle
            let productTypeTitle: any = [{ label: '', value: 0 }];
            if (existingProduct.Product
                && productTypeOptions
                && productTypeOptions.length > 0
            ) {
                productTypeTitle = productTypeOptions.filter(
                    option => option.value === existingProduct?.Product?.productTypeId
                );
            }

            initialFormValues = {
                productId: existingProduct.Product.productId,
                title: existingProduct.Product.title,
                description: existingProduct.Product.description,
                sku: sku,
                productTypeId: existingProduct.Product.productTypeId,
                productTypeTitle: productTypeTitle[0].label,
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialFormValues
        },
        onSubmit: (values: EditProductValues) => {
            let products;
            if (!ID) {
                products = new ProductAndVariations({
                    Product: new Products({
                        title: values.title,
                        description: values.description,
                        productTypeId: values.productTypeId,
                    }),
                    Variations: [new Variations({ sku: values.sku })]
                });
            }

            if (ID) {
                //currently we only support one variation
                let variation;
                if (
                    productState.productAndVariations
                    && productState.productAndVariations?.Variations !== null
                    && productState.productAndVariations.Variations.length > 0
                ) {
                    variation = productState.productAndVariations.Variations[0];
                }
                products = new ProductAndVariations({
                    Product: new Products({
                        ...productState.productAndVariations?.Product,
                        title: values.title,
                        description: values.description,
                        productTypeId: values.productTypeId,
                    }),
                    Variations: [new Variations({
                        ...variation,
                        sku: values.sku,
                    })]
                });
            }

            productActionSave.Save(products);
        },
    });

    const clearForm = () => {
        formik.resetForm();
    }

    function productTypeDropDown() {
        return (
            <Autocomplete
                id="product-type-id"
                options={ productTypeOptions }
                getOptionLabel={ option => option.label }
                onChange={ (event, prod) => {
                    formik.setFieldValue('productTypeId', prod ? prod.value : null)
                    formik.setFieldValue('productTypeTitle', prod ? prod.label : null)
                }}
                value={{ label: formik.values.productTypeTitle, value: formik.values.productTypeId }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Product Type"
                            variant="outlined"
                            name="product_types"
                            required
                        />);
                    }
                }
            />
        );
    }

    return (
        <div>
            <div className={ muiGlobal.paper }>
                <div>
                    <h1>{ !ID && <span>Add Products</span> }</h1>
                    <form onSubmit={ formik.handleSubmit }>
                        <Grid container spacing={ 2 }>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="title"
                                    name="title"
                                    label="Title"
                                    value={ formik.values.title }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 12 } item>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={ formik.values.description }
                                    onChange={ formik.handleChange }
                                    label="Description"
                                    variant="outlined"
                                    multiline
                                    rows={ 4 }
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                { productTypeDropDown() }
                            </Grid>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="sku"
                                    name="sku"
                                    value={ formik.values.sku }
                                    onChange={ formik.handleChange }
                                    label="sku"
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} item></Grid>
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={ clearForm }
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    <Save /> Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
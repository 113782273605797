import ProductType from '@readystock-javascript/common-library/lib/Models/RestAPI/ProductType';

export class ProductTypesState {
    ProductTypes!: ProductType[]
}

export const DefaultProductTypesState = {
    ProductTypes: []
};

export enum ProductTypesActionEnum {
    Loading = "ProductTypesActionEnum_Loading",
    Loaded = "ProductTypesActionEnum_Loaded"
}

export class ProductTypesAction {
    type?: ProductTypesActionEnum
    ProductTypes!: ProductType[]
}

export function LoadProductTypes(ProductTypes: ProductType[]): ProductTypesAction {
    return {
        type: ProductTypesActionEnum.Loaded,
        ProductTypes: ProductTypes
    };
}
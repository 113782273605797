import { VARIANT_REQUEST, VARIANT_SUCCESS, VARIANT_FAILED } from 'shared/state/action-type';

export function variantFailed(error) {
    return {
        type: VARIANT_FAILED,
        payload: error,
    }
}

export function variantRequested() {
    return {
        type: VARIANT_REQUEST,
    };
}

export function variantSuccess(variant) {
    return {
        type: VARIANT_SUCCESS,
        payload: variant,
    };
}
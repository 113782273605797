
import request from 'shared/request';
import { PRODUCT_TYPES_REQUEST, PRODUCT_TYPES_SUCCESS, PRODUCT_TYPES_FAILED } from 'shared/state/action-type';

export function productTypesFailed(error) {
    return {
        type: PRODUCT_TYPES_FAILED,
        payload: error,
    }
}

function productTypesRequested() {
    return {
        type: PRODUCT_TYPES_REQUEST,
    };
}

function productTypesSuccess(productTypes) {
    return {
        type: PRODUCT_TYPES_SUCCESS,
        payload: productTypes,
    };
}

export function fetchProductTypes(parameters = {}) {
    return dispatch => {
        dispatch(productTypesRequested());
        return request.get('product_types', { params: { ...parameters }})
        .then(data => {
            dispatch(productTypesSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(productTypesFailed(error));
            return error;
        });
    }
}
import request from 'shared/request';
import { warehouseRequested, warehouseSuccess, warehouseFailed } from 'data/Warehouse/actions/actions';
import { appMessageSuccess } from 'data/AppMessage/actions/actions';


export function saveWarehouse(parameters = {}) {
    return dispatch => {
        dispatch(warehouseRequested());
        return request.post('warehouses', parameters)
        .then(data => {
            dispatch(warehouseSuccess(data));
            return data;
        })
        .catch(error => {
            if (error.response) {
                dispatch(warehouseFailed(error.response.data.error));
                dispatch(appMessageSuccess([{ message: error.response.data.error, level: 'error' }]));
            }
            // return error;
        });
    }
}

export function updateWarehouse(parameters = {}) {
    return dispatch => {
        dispatch(warehouseRequested());
        request.put('warehouses', parameters)
        .then(data => {
            dispatch(warehouseSuccess(data));
            dispatch(appMessageSuccess([{ message: 'Warehouse updated.', level: 'success' }]));
            return data;
        })
        .catch(error => {
            dispatch(warehouseFailed(error));
            dispatch(appMessageSuccess([{ message: error, level: 'error' }]));
            return error;
        });
    }
}
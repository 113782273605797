import { VARIANT_REQUEST, VARIANT_SUCCESS, VARIANT_FAILED } from 'shared/state/action-type';

import { Variant } from 'data/Variant/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';

export default function VariantReducer (state = new BaseState(), action) {
    switch (action.type) {
        case VARIANT_FAILED:
            return state.merge({
                loading: false,
                loaded: true,
                error: action.error,
            });
        case VARIANT_REQUEST:
            return state.merge({
                loading: true,
                loaded: false,
                error: null,
            });
        case VARIANT_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.data.map(data => {
                    return [data.variationId, new Variant(data)];
                })),
             });
        default:
            return state;
    }
}
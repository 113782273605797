import { APP_MESSAGE_SUCCESS, APP_MESSAGE_REMOVE } from 'shared/state/action-type';

import { AppMessage } from 'data/AppMessage/model';
import BaseState from 'data/BaseState';

import { Map } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

export default function AppMessageReducer (state = new BaseState(), action) {
    switch (action.type) {
        case APP_MESSAGE_REMOVE:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.filter((_, key) => key !== action.payload ),
             });
        case APP_MESSAGE_SUCCESS:
            return state.merge({
                loading: false,
                loaded: true,
                error: false,
                items: state.items.merge(action.payload.map(message => {
                    return [uuidv4(), new AppMessage(message)];
                })),
             });
        default:
            return state;
    }
}
import {
    SalesState,
    SalesAction,
    SalesActionType,
    DefaultSalesState
} from "Actions/SalesActions";
import { SearchStatus } from "Actions/SearchActions";
import SearchReducer from './SearchReducer';
import { TableRowState } from "Components/RSTable";

const SalesReducer = function (state: SalesState = DefaultSalesState, action: SalesAction): SalesState {
    let searchState = SearchReducer(state, action);

    let result: SalesState;
    switch (action.type) {
        case SalesActionType.Initializing:
            result = {
                ...state,
                ...searchState,
                Status: SearchStatus.Initializing,
                Sales: []
            };
            break;
        case SalesActionType.Loaded:
            result = {
                ...state,
                ...searchState,
                Status: SearchStatus.Loaded,
                Sales: action.Sales ?? [],
                TableRowState: TableRowState.DefaultFromList(action.Sales ?? [], 'SaleID')
            };

            break;
        case SalesActionType.ToggleAdvancedFilters:
        case SalesActionType.SetMenuAnchorElement:
        case SalesActionType.UpdateGrid:
        case SalesActionType.CheckRow:
        case SalesActionType.CheckAllRows:
            result = {
                ...state,
                ...searchState
            };
            break;
        default:
            result = state;
    }
    return result;
};

export default SalesReducer;
import { Record } from 'immutable';

export class ProductType extends Record({
    saving: false,
    productTypeId: null,
    name: '',
    description: '',
    createdBy: null,
    dateCreated: '',
    updateBy: null,
    dateUpdated: '',
}) {

}
import React from 'react';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';

import { getBarcodeTypeState } from 'data/BarcodeType/selectors/index';
import { saveBarcode } from 'data/Barcode/actions/saveBarcode';
import globalMui from 'shared/globalMaterialUiClasses';

import {
    Grid,
    TextField,
    Button,
    Dialog,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';

interface barcodeEditValues {
    prodId: number,
    barcodeId: number,
    barcodeData: string,
    barcodeTypeId: number,
    barcodeTypeTitle: string,
}

interface barcodeTypes {
    barcodeTypeId: number,
    name: string,
    description: string,
}

type BarcodeEditType = {
    productId: number,
};

const BarcodeEdit: React.FC<BarcodeEditType> = (props) => {
    const { productId } = props;
    const muiGlobal = globalMui();
    const [open, setOpen] = React.useState(false);
    const barcodeTypes = useSelector(state => getBarcodeTypeState(state));

    const dispatch = useDispatch();

    let initialFormValues: barcodeEditValues = {
        prodId: productId,
        barcodeId: 0,
        barcodeData: '',
        barcodeTypeId: 0,
        barcodeTypeTitle: '',
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialFormValues
        },
        onSubmit: (values: barcodeEditValues) => {
            const data = new Array({
                productId: values.prodId,
                barcodeTypeId: values.barcodeTypeId,
                barcode: values.barcodeData,
            });

            dispatch(saveBarcode(data));
            formik.resetForm();
            setOpen(false);
        },
    });

    function barcodeTypesDropdown() {

        const barcodeTypeOptions = barcodeTypes.map((barcode: barcodeTypes) => {
            return {
                label: barcode.name,
                value: barcode.barcodeTypeId
            };
        }).valueSeq() ?? [];

        return (
            <Autocomplete
                id="barcode-type-id"
                options={ barcodeTypeOptions }
                getOptionLabel={ option => option.label }
                onChange={ (event, prod) => {
                    formik.setFieldValue('barcodeTypeId', prod ? prod.value : null)
                    formik.setFieldValue('barcodeTypeTitle', prod ? prod.label : null)
                }}
                value={{ label: formik.values.barcodeTypeTitle, value: formik.values.barcodeTypeId }}
                getOptionSelected={ (option, value) => option.label === value.label }
                renderInput={
                    params => {
                        return (<TextField
                            {...params}
                            label="Barcode Type"
                            variant="outlined"
                            name="barcode_types"
                            required
                        />);
                    }
                }
            />
        );
    }

    const openBarcodeEdit = () => {
        setOpen(true);
    };

    const closeBarcodeEdit = () => {
        setOpen(false);
    };

    return (
        <div>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 8 }>
                    <h3 className={ muiGlobal.noPnoM}>Barcodes</h3>
                </Grid>
                <Grid item xs={ 4 } className={ muiGlobal.right }>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={ openBarcodeEdit }>
                        Add Barcode
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={ open }
                onClose={ closeBarcodeEdit }
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={ muiGlobal.paper }>
                    <h2>Add Barcode</h2>
                    <form onSubmit={ formik.handleSubmit }>
                        <Grid container spacing={ 2 }>
                            <Grid xs={ 6 } item>
                                <TextField
                                    id="barcodeData"
                                    name="barcodeData"
                                    label="Barcode Data"
                                    value={ formik.values.barcodeData }
                                    onChange={ formik.handleChange }
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid xs={ 6 } item>
                                { barcodeTypesDropdown() }
                            </Grid>
                            <Grid style={{ flexGrow: 1 }} item></Grid>
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={ closeBarcodeEdit }
                                >
                                    Close
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default BarcodeEdit;
import React from 'react';

import globalMui from 'shared/globalMaterialUiClasses';

import {
    Breadcrumbs,
    Typography,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { Map } from 'immutable';

const SharedBreadcrumbs = (props) => {
    const { display, links, last } = props;
    const muiGlobal = globalMui();

    if (!display) {
        return null;
    }

    function buildLinkList () {
        const linkList = new Map(links);

        return linkList.map((link, key) => {
            console.log(key, link);
            return <Link key={ `breadcrumbs-${key}` } to={ link.link }>{ link.name }</Link>
        }).toList().toArray() ;
    }

    return (
        <Breadcrumbs className={ muiGlobal.breadcrumbs }>
            { buildLinkList() }
            <Typography color="textPrimary">{ last }</Typography>
        </Breadcrumbs>
    );
};

export default SharedBreadcrumbs;
import React, { useEffect } from 'react';
import {
    RSTableInfoColumn, RSTableInfo
} from 'Components/RSTable';
import { default as InventoryModel } from '@readystock-javascript/common-library/lib/Models/RestAPI/Inventory';
import { useSelector } from 'Utils/useSelector';
import { useDispatch } from 'react-redux';
import { InventoriesActions } from 'Actions/InventoriesActions';
import Search from 'Components/Search/Search';
import { restClient } from 'Utils/ApiClient';

export default function Inventory() {

    let inventoryActions = new InventoriesActions();
    let dispatch = useDispatch();
    let InventoryState = useSelector(state => state.Inventories)
    let Inventory = useSelector<InventoryModel[]>(state => state.Inventories.Inventory);
    useEffect(() => {
        restClient.Inventory.GetInventory().then(data => {
            dispatch(new InventoriesActions().LoadInventory(data))
        });
    }, [dispatch, InventoryState.UpdateRequestDate]);

    let columns: RSTableInfoColumn[] = [];
    columns[columns.length] = new RSTableInfoColumn({ Name: "inventoryId", Label: "Inventory ID" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "variation.product.title", Label: "Title" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "variation.sku", Label: "SKU" });
    columns[columns.length] = new RSTableInfoColumn({ Name: "qty", Label: "QTY" });
    let RSTable = RSTableInfo.BuildRSTableInfo(Inventory, "inventoryId", columns, InventoryState.TableRowState, InventoryState.TopCheckBoxIsChecked);

    return (
        <Search RSTableInfo={RSTable} SearchActions={inventoryActions} SearchState={InventoryState} />
    );
}